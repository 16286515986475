import { useMemo, useState } from 'react';
import { VALIDATORS } from '../enums/validators';

export default (fields) => {
  const [values, update] = useState(
    Object
      .entries(fields)
      .reduce((accumulator, [id, { defaultValue }]) => ({
        ...accumulator,
        [id]: defaultValue,
        [`valid_${id}`]: false,
      }), {}),
  );

  return useMemo(() => (
    Object
      .entries(fields)
      .reduce((accumulator, [id, {
        required = false,
        autoComplete = undefined,
        validator = VALIDATORS.default,
        isValid = undefined,
        defaultValue,
        ...field
      }]) => ({
        ...accumulator,
        [id]: {
          id,
          required,
          autoComplete,
          defaultValue,
          ...field,
          validator,
          isValid: () => (
            typeof isValid === 'function'
              ? isValid(values[id], values)
              : !required || Boolean(values[id])
          ),
          value: values[id],
          touched: values[id] !== defaultValue,
          valid: values[`valid_${id}`],
          setValid: (value) => update((state) => ({
            ...state,
            [`valid_${id}`]: value,
          })),
          setValue: (value) => update((state) => ({
            ...state,
            [id]: value,
          })),
        },
      }), {})
  ), [fields, values]);
};
