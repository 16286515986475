export default (selectedPractictioners) => {
  const noPractitionersSelected = Object
    .values(selectedPractictioners)
    .reduce((out, selected) => out && !selected, true);

  return ({ practitioner }) => (
    practitioner === null
    || noPractitionersSelected
    || selectedPractictioners[practitioner.sfid]
  );
};
