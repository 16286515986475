import { useSelector } from 'react-redux';
import React, {
  forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState,
} from 'react';
import modalActions from '../actions/modalActions';
import './Modal.sass';
import { ReactComponent as Close } from '../static/img/close.svg';
import ParentPageInfo from '../contexts/parentWindowInfo';

const minMax = (value, lowerBound, higherBound) => Math.min(higherBound, Math.max(lowerBound, value));

export default forwardRef(({
  type,
  children,
  noClose = false,
  onShow = () => {},
  onHide = () => {},
}, ref) => {
  const { showModal, hideModal } = modalActions();
  const modal = useSelector(({ modals }) => modals[type]);
  const modalRef = useRef();

  const [iframePosition, setIframePosition] = useState({});
  const [modalSize, setModalSize] = useState({
    width: null,
    height: null,
  });

  useEffect(() => {
    if (!modalRef.current || !window.ResizeObserver) {
      return () => {};
    }

    const observer = new ResizeObserver(([event]) => {
      setModalSize({
        width: event.borderBoxSize.inlineSize,
        height: event.borderBoxSize.blockSize,
      });
    });
    observer.observe(modalRef.current);

    return () => observer.disconnect();
  }, [modalRef.current]);

  const parentPageInfo = useContext(ParentPageInfo);
  const position = useMemo(() => {
    const {
      scrollTop, clientHeight, offsetTop,
    } = parentPageInfo;

    if (!modalRef.current) {
      return null;
    }

    const width = modalSize.width || modalRef.current.scrollWidth;
    const height = modalSize.height || modalRef.current.scrollHeight;

    return {
      top: minMax(
        (clientHeight - (clientHeight / 1.6)) - (height / 2) + scrollTop - offsetTop,
        20,
        window.innerHeight - height - 20,
      ),
      left: (window.innerWidth - width) / 2,
    };
  }, [modalRef.current, modalSize]);

  const resize = () => {
    setTimeout(() => {
      const height = modalSize.height || modalRef.current.scrollHeight;
      if (height > window.innerHeight && position && position.top) {
        setIframePosition({ top: 5 });
        window.parentIFrame.size(height + 10);
      }
    }, 100)
  };

  const show = (data) => {
    onShow(data);
    showModal(type, data);
    if ('parentIFrame' in window) {
      window.parentIFrame.autoResize(false);
    }
    resize();
  };

  const hide = () => {
    onHide();
    hideModal(type);
    resize();
    if ('parentIFrame' in window) {
      setIframePosition({});
      window.parentIFrame.autoResize(true);
    }
  };

  useImperativeHandle(ref, () => ({
    show, hide,
  }));

  return (
    <div className={`modal ${modal ? '' : 'modal--hidden'}`}>
      <div
        className="modal-content__wrapper"
        ref={modalRef}
        style={position && {
          position: 'absolute',
          ...position,
          ...iframePosition,
        }}
      >
        <div className="modal-content">
          {!noClose && (
            <button type="button" className="modal-close__button" onClick={() => hide()}>
              <Close />
            </button>
          )}
          { modal && children(modal, hide) }
        </div>
      </div>
    </div>
  );
});
