import React, { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router';

import './HMOT.sass';
import { useSelector } from 'react-redux';
import useHMOT from '../hooks/useHMOT';
import DaysToggle from '../components/DaysToggle';
import TimeslotToggle from '../components/TimeslotToggle';
import FlatList from '../components/FlatList';
import scrollTo from '../utils/scrollTo';
import purifyObject from '../utils/purifyObject';
import useAuthentication from '../hooks/useAuthentication';
import SelectBox from '../components/SelectBox';
import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';
import CTAButton from '../components/CTAButton';

export default () => {
  const { gymId } = useParams();
  const scrollRef = useRef();
  const { access_token: accessToken, login } = useAuthentication();
  const { userGym, gymEligibility } = useSelector(({ user }) => user);
  const {
    days, timeslots, selectedTimeslot, reservations, selectedReservation, genders, terms, toggleTerms,
  } = useHMOT(gymId);
  const history = useHistory();
  const toggleCtaOverlay = (event) => {
    event.target.previousElementSibling.classList.toggle('m--open');
  };

  useEffect(() => {
    if (selectedReservation !== null) {
      scrollTo(scrollRef);
      toggleTerms(false);
    }
  }, [selectedReservation]);

  return (
    <div className="page m--hmot">
      {!accessToken ? (
        <>
          <button
            className="cta mt-20"
            onClick={async () => {
              login();
            }}
          >
            Login
          </button>
        </>
      ) : (
        <>
          {gymEligibility ? (
            <>
              {userGym && userGym.sfid !== gymId ? (
                <>
                  <div className="pattern" />
                  <div className="header__wrapper mb-30">
                    <div className="header">
                      <div className="h1">
                        Health MOT
                      </div>
                    </div>
                  </div>
                  <div className="h1 mb-30">Looking to book your Health MOT?</div>
                  <p className="mb-30">
                    You’ll need to do this through your home gym –
                    {' '}
                    {userGym.site.name}
                  </p>
                  <a className="home-gym__link" href={`${userGym.site.site_website}/services/book-a-health-mot`} target="_top">{userGym.site.name}</a>
                </>
              ) : (
                <>
                  <div className="pattern" />
                  <div className="header__wrapper">
                    <div className="header">
                      <div className="h1">Health MOT</div>
                    </div>
                  </div>
                  <div className="gender__wrapper">
                    <div className="gender__label">Staff gender</div>
                    <SelectBox data={genders} type="radio">
                      <div className="custom-select__list">
                        <FlatList
                          data={genders}
                          renderItem={({
                            item: { name }, select, selected,
                          }) => (
                            <div className="custom-select__li" key={name}>
                              <input
                                type="radio"
                                id={name}
                                className="custom-radio__value"
                                onChange={select}
                                checked={selected}
                              />
                              <label className="custom-select__label" htmlFor={name}>
                                {name}
                              </label>
                            </div>
                          )}
                        />
                      </div>
                    </SelectBox>
                  </div>
                  <div className="datepicker__wrapper mt-20">
                    <DaysToggle {...days} showMonth />
                    <TimeslotToggle
                      timeslots={timeslots}
                      renderEmpty={() => (
                        <p className="no-staff-available">No available staff members for this timeframe</p>
                      )}
                    />
                  </div>
                  {selectedTimeslot && (
                  <FlatList
                    data={reservations}
                    renderItem={({
                      item: {
                        sfid, staff, mFrom, mTo, product, room,
                      }, select,
                    }) => (
                      <div className="card" key={sfid}>
                        <div className="staff">
                          {staff && staff.gender && staff.gender === 'Male' && 'Mr '}
                          {staff && staff.gender && staff.gender === 'Female' && 'Mrs '}
                          {staff && staff.name}
                        </div>
                        <div className="button__wrapper">
                          <button
                            className={`cta ${selectedReservation && sfid === selectedReservation.sfid && 'm--close'}`}
                            onClick={() => select()}
                          >
                            {selectedReservation && sfid === selectedReservation.sfid ? 'Close' : 'Select'}
                          </button>
                        </div>
                      </div>
                    )}
                    renderEmpty={() => <p className="no-items-found">Sorry, this filter has returned no results</p>}
                  />
                  ) }

                  {selectedReservation && (
                  <div className="wysiwyg" ref={scrollRef}>
                    <div dangerouslySetInnerHTML={{
                      __html: selectedReservation.product.what_to_expect,
                    }}
                    />
                    <div className="mt-30">
                      <div className="custom-checkbox__wrapper">
                        <input type="checkbox" className="custom-checkbox__value" id="tocCheckbox" checked={terms} onChange={() => toggleTerms()} />
                        <label className="custom-checkbox__label" htmlFor="tocCheckbox">
                          <span className="custom-checkbox__faux">
                            <Checkmark />
                          </span>
                      I accept the data protection statement above as well as the &nbsp;
                        </label>
                        <a target="_blank" className="link" href="https://www.nuffieldhealth.com/terms/nuffield-health-website-terms-and-conditions/nuffield-health-account-terms-and-conditions">Terms and Conditions</a>
                        <label className="custom-checkbox__label" htmlFor="tocCheckbox">
                      &nbsp;and&nbsp;
                        </label>
                        <a target="_blank" className="link" href="https://www.nuffieldhealth.com/privacy">Privacy Policy</a>
                      </div>
                    </div>
                    <CTAButton
                      disabled={!terms}
                      className="cta m--big mt-20"
                      action={{
                        call: selectedReservation.book,
                        name: 'Confirm Booking',
                        type: 'BOOK',
                      }}
                      onAction={() => {
                        history.push(`/gyms/${gymId}/hmot/booked/`, purifyObject({
                          reservation: selectedReservation,
                        }));
                      }}
                    />
                  </div>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <div className="header__wrapper">
                <div className="header">
                  <div className="h1">
                    {'You aren\'t a gym member'}
                  </div>
                </div>
              </div>
              <p>
                {'You need to have a gym membership to book a free Health MOT. We\'d still like to help you on your wellbeing journey, so why not take a look at our '}
                <a href="https://www.nuffieldhealth.com/article" target="_top">free health advice?</a>
              </p>
              <p>
                {'b. If you are a member, there may be a problem with your account, so please call our support centre on '}
                <a href="tel:03301279829">0330 127 4689</a>
              </p>
            </>
          )}
        </>
      )}
    </div>
  );
};
