import useActions from '../hooks/useActions';
import myBookings from '../reducers/myBookings';

const setStatus = (booking) => ({
  type: myBookings.types.setStatus,
  payload: booking,
});

const setPending = (reservationId) => setStatus({
  status: 'PENDING',
  reservation: reservationId,
});

const cancelBookingAction = (reservationId) => ({
  type: myBookings.types.cancelBooking,
  payload: reservationId,
});

export default (bookingEngine) => useActions({
  setNextPage: (page) => async (dispatch) => {
    dispatch({
      type: myBookings.types.setNextPage,
      payload: page,
    });
  },

  resetMyBookings: () => (dispatch) => dispatch({
    type: myBookings.types.reset,
  }),

  fetchMyBookings: (past = false, page = 1) => async (dispatch) => {
    const { items, next } = await bookingEngine.getMyBookings({ past, page });

    dispatch({
      type: myBookings.types.addBookings,
      payload: items,
    });

    dispatch({
      type: myBookings.types.setNextPage,
      payload: past
        ? {
          past: next,
        } : {
          future: next,
        },
    });

    if (!past) {
      dispatch({
        type: myBookings.types.setLastBooking,
        payload: items[items.length - 1],
      });
    }
  },

  cancel: (reservationId, cancelBooking = async () => {}) => async (dispatch) => {
    await dispatch(setPending(reservationId));
    await cancelBooking(bookingEngine);
    dispatch(cancelBookingAction(reservationId));
  },
});
