export default () => window.location.search
  .substr(1)
  .split('&')
  .reduce((params, keyValue) => {
    const [key, value] = keyValue.split('=');
    return {
      ...params,
      [key]: decodeURIComponent(value),
    };
  }, {});
