import useActions from '../hooks/useActions';
import events from '../reducers/events';

export default (bookingEngine) => useActions({

  selectEvent: (reservationId) => async (dispatch) => {
    dispatch({
      type: events.types.selectEvent,
      payload: { reservationId },
    });
  },

  unSelectEvent: () => async (dispatch) => {
    dispatch({
      type: events.types.selectEvent,
      payload: { reservationId: null },
    });
  },

  createEventReservation: ({ ...data }) => async (dispatch) => {
    await bookingEngine.createEventReservation(data);
  },

  fetchForReservation: (resource, reservationId) => async (dispatch) => {
    const { reservation, items } = await bookingEngine.getEventReservation(resource, reservationId);
    await dispatch({
      type: events.types.addEvents,
      payload: { reservation, items },
    });
  },
});
