import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import useBookingEngine from '../useBookingEngine';
import usePractitioners from './usePractitioners';
import useTimeOfDay from './useTimeOfDay';
import useDays from '../useDays';
import useIntensityLevel from './useIntensityLevel';
import useClassCategories from './useClassCategories';
import gymClassActions from '../../actions/gymClassActions';
import dateTimeFilter from './filters/dateTimeFilter';
import placesLeftFilter from './filters/placesLeftFilter';
import practitionerFilter from './filters/practitionerFilter';
import intensityFilter from './filters/intensityFilter';
import classCategoryFilter from './filters/classCategoryFilter';
import useAuthentication from '../useAuthentication';
import locationFilter from './filters/locationFilter';
import getLoggedInAction from './getLoggedInAction';
import getLoggedOutAction from './getLoggedOutAction';

export default (location, { reloadDaysOnFilter = true } = {}) => {
  const { login, access_token: accessToken } = useAuthentication();
  const { gymEligibility } = useSelector(({ user }) => user);

  const bookingEngine = useBookingEngine();
  const actions = gymClassActions(bookingEngine);
  const { fetchForLocation } = actions;

  const items = useSelector(({ gymClasses }) => gymClasses);
  const bookableItems = useMemo(() => (
    orderBy(
      Object.values(items),
      ['from_date'],
      'asc',
    )
  ), [items]);

  const [onlyWithPlacesLeft, setOnlyWithPlacesLeft] = useState(false);
  const [selectedDay, days, daysActions] = useDays(9);
  const [selectedTime, timeOfDay] = useTimeOfDay();
  const [selectedPractitioners, practitioners] = usePractitioners(bookableItems);
  const [selectedIntensityLevel, intensityLevels] = useIntensityLevel();
  const [selectedClassCategories, classCategories] = useClassCategories();

  const filteredItems = useMemo(() => (
    bookableItems
      .filter(locationFilter(location))
      .filter(({ status }) => status !== 'Canceled')
      .filter(({ to_date }) => moment(to_date).isAfter())
      .filter(dateTimeFilter(selectedDay, selectedTime))
      .filter(placesLeftFilter(onlyWithPlacesLeft))
      .filter(practitionerFilter(selectedPractitioners))
      .filter(intensityFilter(selectedIntensityLevel))
      .filter(classCategoryFilter(selectedClassCategories))
      .map((item) => ({
        ...item,
        type: 'GYMCLASS',
        action: (() => {
          if (moment(item.from_date).isBefore()) {
            return null;
          }
          return accessToken
            ? getLoggedInAction(item, actions, gymEligibility)
            : getLoggedOutAction(item, { login, ...actions });
        })(),
      }))
  ), [
    bookableItems,
    location,
    selectedDay,
    selectedTime,
    onlyWithPlacesLeft,
    selectedPractitioners,
    selectedIntensityLevel,
    selectedClassCategories,
    accessToken,
    actions,
    gymEligibility,
    login,
  ]);

  useEffect(() => {
    fetchForLocation(location, days.map(({ item: { date } }) => date));
  }, [location]);

  useEffect(() => {
    if (reloadDaysOnFilter) {
      fetchForLocation(location, [selectedDay.date]);
    }
  }, [selectedDay]);

  return {
    days,
    timeOfDay,
    practitioners,
    onlyWithPlacesLeft,
    setOnlyWithPlacesLeft,
    intensityLevels,
    filteredItems,
    classCategories,
    refresh: () => {
      daysActions.refresh();
      if (!reloadDaysOnFilter) {
        fetchForLocation(location, days.map(({item: {date}}) => date));
      }
    },
  };
};
