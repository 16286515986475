import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import loginActions from '../actions/loginActions';

export default () => {
  const authentication = useSelector(({ authentication }) => authentication);
  const actions = loginActions(new global.Authentication());

  return useMemo(() => ({
    ...actions,
    ...authentication,
  }), [authentication]);
};
