import React from 'react';
import ReactDOM from 'react-dom';
import './index.sass';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';
import OIDCB2CAuthentication from './services/OIDCB2CAuthentication';
import WebTagManager from './services/WebTagManager';


global.BOOKING_ENGINE_BASE_URL = process.env.REACT_APP_BOOKING_ENGINE_BASE_URL;
global.BOOKING_ENGINE_VIA_APIM = process.env.REACT_APP_BOOKING_ENGINE_VIA_APIM === 'true';
global.APIM_SUBSCRIPTION_KEY = process.env.REACT_APP_APIM_SUBSCRIPTION_KEY;
global.APIM_BASE_URL = process.env.REACT_APP_APIM_BASE_URL;
global.APIM_BOOKING_ENGINE = process.env.REACT_APP_APIM_BOOKING_ENGINE;
global.APIM_CONSULTANT_BOOKINGS = process.env.REACT_APP_APIM_CONSULTANT_BOOKINGS;
global.Authentication = OIDCB2CAuthentication;
global.TagManager = new WebTagManager();

// object containing devtools exposed to the window.
global.devtools = {};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
