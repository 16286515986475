import React, { useState } from 'react';

import { useHistory, useParams } from 'react-router';
import moment from 'moment-timezone';
import purifyObject from '../utils/purifyObject';
import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';
import './EventForm.sass';
import CTAButton from './CTAButton';
import FormField from './FormField';
import eventsActions from '../actions/eventsActions';
import useForm from '../hooks/useForm';
import { VALIDATORS } from '../enums/validators';
import validateSFPhoneNumber from '../utils/validateSFPhoneNumber';

const DETAILS_FORM = {
  first_name: {
    type: 'text',
    label: 'First name',
    required: true,
  },
  last_name: {
    type: 'text',
    label: 'Last name',
    required: true,
  },
  date_of_birth: {
    type: 'custom_date',
    label: 'Date of birth',
    required: true,
    validator: VALIDATORS.custom,
    isValid: (date) => {
      const mDate = moment(
        `${date.year}-${date.month}-${date.day}`,
        'YYYY-MM-DD',
      );

      return Boolean(date.day)
        && Boolean(date.month)
        && Boolean(date.year)
        && mDate.isValid();
    },
    defaultValue: {
      day: undefined,
      month: undefined,
      year: undefined,
    },
  },
  email: {
    type: 'email',
    label: 'Email Address',
    required: true,
  },
  postcode: {
    type: 'text',
    label: 'Postcode',
    autoComplete: 'off',
    required: true,
    validator: VALIDATORS.loqate,
  },
  phone_number: {
    type: 'tel',
    label: 'Phone number',
    required: true,
    validator: VALIDATORS.custom,
    isValid: (value) => Boolean(value) && validateSFPhoneNumber(value),
  },
  guest: {
    type: 'checkboxgroup',
    defaultValue: {
      guest: false,
    },
    choices: [
      ['guest', 'Bring a guest'],
    ],
  },
  communication_preferences: {
    type: 'checkboxgroup',
    defaultValue: {
      email: false,
      sms: false,
      phone: false,
    },
    choices: [
      ['email', 'Email'],
      ['sms', 'SMS'],
      ['phone', 'Phone'],
    ],
  },
};

export default ({ selectedEvent, profile, createEventReservation }) => {
  const history = useHistory();
  const { resourceId, reservationId } = useParams();
  const { unSelectEvent } = eventsActions();

  const [toc, setToc] = useState(false);

  const form = useForm(DETAILS_FORM);

  const formComplete = Object
    .values(form)
    .reduce((valid, field) => valid && field.valid);

  const action = {
    call: formComplete && toc
      ? async () => createEventReservation({
        reservation: selectedEvent,
        firstName: form.first_name.value,
        lastName: form.last_name.value,
        email: form.email.value,
        postcode: form.postcode.value,
        guest: form.guest.value.guest,
        phoneNumber: form.phone_number.value.startsWith('+')
          ? form.phone_number.value
          : `+${form.phone_number.value}`,
        smsService: form.communication_preferences.value.sms,
        emailService: form.communication_preferences.value.email,
        phoneService: form.communication_preferences.value.phone,
        dateOfBirth:
          moment({
            ...form.date_of_birth.value,
            month: form.date_of_birth.value.month - 1, // Months are zero-indexed
          }).format('YYYY-MM-DD'),
      })
      : null,
    name: 'Book',
    type: 'BOOK',
  };

  return (
    <div className="event-form">
      <div className="mt-30 h1">Your details</div>
      <div>
        Please fill out your details. We need these to confirm your appointment
      </div>
      <div className="mt-30 input-fields">
        <div className="form-field__wrapper">
          <FormField
            field={form.first_name}
            errorText="Please enter your first name"
          />
        </div>
        <div className="form-field__wrapper">
          <FormField
            field={form.last_name}
            errorText="Please enter your last name"
          />
        </div>
        <div className="form-field__wrapper">
          <FormField
            field={form.email}
            errorText="Please enter your email address"
          />
        </div>
        <div className="form-field__wrapper">
          <FormField
            field={form.postcode}
            errorText="Please enter a valid postcode"
          />
        </div>
        <div className="form-field__wrapper">
          <FormField
            field={form.phone_number}
            errorText="Please enter your phone number"
          />
        </div>
        <div className="form-field__wrapper">
          <FormField
            field={form.date_of_birth}
            errorText="Please select your date of birth"
          />
        </div>
      </div>
      <div className="mt-30">
        <FormField field={form.guest} />
      </div>
      <div className="subscription-box mt-30">
        <div>
          To continue to receive communications from Nuffield Health about our exclusive offers, products and services, then please tell us how you'd like to be contacted by ticking the relevant boxes below:
        </div>
        <FormField field={form.communication_preferences} />
        <div className="mt-30">
          On occasion Nuffield Health may contact you with pertinent service information in regards to services we provide. Note that you can either amend or withdraw your consent at any time.
          For information about where your personal data may be processed, how it may be processed and for details of our Data Protection Officer, please see our Privacy Policy.
        </div>
      </div>
      <div className="mt-30">
        <div className="custom-checkbox__wrapper terms-checkbox">
          <input type="checkbox" className="custom-checkbox__value" id="tocCheckbox" value={toc} onChange={(e) => setToc(!toc)} />
          <label className="custom-checkbox__label" htmlFor="tocCheckbox">
            <span className="custom-checkbox__faux">
              <Checkmark />
            </span>
            I agree to the&nbsp;
          </label>
          <a target="_blank" className="link" href="https://www.nuffieldhealth.com/terms/nuffield-health-website-terms-and-conditions">terms and conditions</a>
          <label className="custom-checkbox__label" htmlFor="tocCheckbox">
            &nbsp;and&nbsp;
          </label>
          <a target="_blank" className="link" href="https://www.nuffieldhealth.com/privacy">privacy policy</a>
        </div>
      </div>
      <div className="cta-group mt-30">
        <CTAButton
          disabled={!toc}
          className="cta m--big"
          action={action}
          onAction={() => {
            history.push(`/hospitals/${resourceId}/events/${reservationId}/booked/`, purifyObject({
              event: selectedEvent,
            }));
          }}
        />
        <CTAButton
          className="back-link"
          action={{
            call: async () => unSelectEvent(),
            name: 'Back',
            type: 'BACK',
          }}
        />
      </div>
    </div>
  );
};
