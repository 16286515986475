import React from 'react';
import FlatList from './FlatList';
import './TimeslotToggle.sass';

export default ({
  timeslots,
  renderEmpty = () => null,
  formatTime = (timeslot) => timeslot,
}) => (
  <div className="timeslots__wrapper">
    <FlatList
      data={timeslots}
      renderItem={({
        item: { name, timeslots: groupedTimeslots }, show = true, select, selected,
      }) => (
        <div className="day-part__wrapper">
          <button className="day-part__title" onClick={select}>{name}</button>
          {show ? (
            <FlatList
              data={groupedTimeslots}
              renderItem={({
                item: [timeslot], select: slotSelect, selected: slotSelected, show: showTimeslot = true,
              }) => (
                <>
                  {showTimeslot
                    ? (
                      <button
                        key={timeslot}
                        onClick={slotSelect}
                        className={`timeslot__btn ${slotSelected && 'm--active'}`}
                      >
                        {` ${formatTime(timeslot)} `}
                      </button>
                    ) : ''}
                </>
              )}
              renderEmpty={renderEmpty}
            />
          ) : <p className="no-staff-available">Sorry, this filter has returned no results</p>}
        </div>
      )}
    />

  </div>
);
