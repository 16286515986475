export const INSURERS = [
  ["Aviva", "Aviva"],
  ["AXA PPP Healthcare", "AXA PPP Healthcare"],
  ["Bupa", "Bupa"],
  ["Bupa Global", "Bupa Global"],
  ["Cigna Healthcare", "Cigna Healthcare"],
  ["Cigna International", "Cigna International"],
  ["Vitality Health", "Vitality Health"],
  ["WPA", "WPA"],
  ["A La Carte Health (ALC Health)", "A La Carte Health (ALC Health)"],
  ["Alliance Surgical", "Alliance Surgical"],
  ["Allianz Worldwide Care Limited", "Allianz Worldwide Care Limited"],
  ["Assist Card Espana", "Assist Card Espana"],
  ["The Exeter", "The Exeter"],
  ["Healix Health Services Limited", "Healix Health Services Limited"],
  ["Now Healthcare International", "Now Healthcare International"],
  ["Health Partners Europe Ltd", "Health Partners Europe Ltd"],
  ["Northumbria Police Federation", "Northumbria Police Federation"],
  ["Police Mutual", "Police Mutual"],
  ["Sovereign Risk Management Ltd", "Sovereign Risk Management Ltd"],
  ["TrusteDoctor", "TrusteDoctor"],
  ["First Health Trust Ltd", "First Health Trust Ltd"],
  ["General & Medical", "General & Medical"],
  ["Global Response", "Global Response"],
  ["H3 Insurance", "H3 Insurance"],
  ["HealthCare International Global Network Ltd", "HealthCare International Global Network Ltd"],
  ["Healthcare RM", "Healthcare RM"],
  ["Healthnow AIG", "Healthnow AIG"],
  ["Hospital and Medical Care Association (HMCA)", "Hospital and Medical Care Association (HMCA)"],
  ["HTH Worldwide / GeoBlue", "HTH Worldwide / GeoBlue"],
  ["International Medical Group (IMG)", "International Medical Group (IMG)"],
  ["International SOS", "International SOS"],
  ["International Tricare", "International Tricare"],
  ["Multiplex", "Multiplex"],
  ["Preferred Health Care Ltd", "Preferred Health Care Ltd"],
  ["United Healthcare Global Insurance", "United Healthcare Global Insurance"],
]