import React from 'react';
import { useLocation } from 'react-router';
import './HMOTConfirm.sass';
import moment from 'moment-timezone';
import AddToCalendar from 'react-add-to-calendar';
import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';
import { ReactComponent as ArrowRight } from '../static/img/arrow-right.svg';

export default () => {
  const { state } = useLocation();
  const {
    reservation: {
      from_date: fromDate,
      to_date: toDate,
      product,
      room,
      staff,
    },
  } = state;

  const mDate = moment(fromDate).startOf('day');
  const mStart = moment(fromDate);
  const mEnd = moment(toDate);

  return (
    <div className="page m--hmotconfirm">
      <div className="pattern" />
      <div className="header__wrapper">
        <div className="header">
          <div className="h1">Health MOT</div>
        </div>
      </div>
      <div className="booked">
        <span className="icon__wrapper"><Checkmark /></span>
        Booked
      </div>
      <span className="divider mb-25" />

      <div className="product-title">{product.name}</div>
      <div className="details__wrapper">
        <table className="details">
          <tr>
            <td>Time</td>
            <td>
              {mStart.format('HH:mm')}
              {' '}
-
              {mEnd.format('HH:mm')}
              {' '}
              {mDate.format('dddd D MMMM')}
            </td>
          </tr>
          <tr>
            <td>Duration</td>
            <td>{`${mEnd.diff(mStart, 'minutes')} minutes`}</td>
          </tr>
          <tr>
            <td>Therapist</td>
            <td>
              {staff.gender && staff.gender === 'Male' && 'Mr '}
              {staff.gender && staff.gender === 'Female' && 'Mrs '}
              {staff.name}
            </td>
          </tr>
          <tr>
            <td>Location</td>
            <td>
              {room.name}
              {' '}
at
              {room.facility.name}
            </td>
          </tr>
        </table>
        <div className="cancelation-text">
          <div className="add-to-calendar-link__wrapper">
            <AddToCalendar
              event={{
                title: product.name,
                location: `${room.name}, ${room.facility.name}`,
                description: '',
                startTime: mStart.format(),
                endTime: mEnd.format(),
              }}
            />
            <ArrowRight />
          </div>
        </div>
      </div>
      <span className="divider mb-25" />
      <div className="h1">About your booking</div>
      <div className="bold mb-20">To prepare for your Health MOT:</div>
      <div className="wysiwyg">
        <div dangerouslySetInnerHTML={{
          __html: product.prerequisite,
        }}
        />
      </div>
    </div>
  );
};
