import React, { useState } from 'react';
import EventProduct from './EventProduct';
import EventTimeslot from './EventTimeSlot';
import FlatList from './FlatList';
import useSelect from '../hooks/useSelect';
import CTAButton from './CTAButton';

import './EventTimeSlots.sass';

export default ({ reservation, items }) => {
  const [showTimeslots, setShowTimeSlots] = useState(false);

  const [selected, selectableItems] = useSelect(items, {
    defaultSelected: -1,
    nullable: true,
  });

  const toggle = () => {
    setShowTimeSlots(!showTimeslots);
  };

  return (
    <>
      {reservation
      && (
      <EventProduct
        item={reservation}
        toggleActions={
          selectableItems.length > 0
            ? {
              call: toggle,
              name: showTimeslots ? 'Close' : 'Pick Slot',
              type: 'TOGGLE',
            }
            : {
              call: null,
              name: 'Event Full',
              type: 'IS_FULL',
            }
        }
      />
      )}

      {showTimeslots && (
        <div className="timeslots__wrapper">
          <div className="day-part__wrapper">
            <div className="day-part__title">Choose a time slot</div>
            <FlatList
              data={selectableItems}
              renderItem={(item, index) => (
                <EventTimeslot item={item} key={index} />
              )}
              renderEmpty={() => (
                <div className="no-timeslots">
                  <p>No timeslots available</p>
                </div>
              )}
            />
          </div>
          {selected
            && (
            <div className="book-cta__wrapper">
              <CTAButton
                action={{
                  name: 'Book',
                  type: 'Book',
                  call: selected.action.call,
                }}
              />
            </div>
            )}
        </div>
      )}
    </>
  );
};
