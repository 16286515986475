export default (prefix, initialState, actionHandlers) => {
  const identifier = (key) => `${prefix}_${key}`;

  const handlers = {
    [identifier('reset')]: () => initialState,
  };
  const types = {
    reset: identifier('reset'),
  };

  Object
    .entries(actionHandlers)
    .forEach(([key, handler]) => {
      types[key] = identifier(key);
      handlers[identifier(key)] = handler;
    });

  const reducer = (state, { type, payload }) => {
    const currentState = state || initialState;
    const handler = handlers[type];
    return handler !== undefined
      ? handler(currentState, payload)
      : currentState;
  };

  reducer.types = types;

  return reducer;
};
