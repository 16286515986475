import error from '../reducers/error';
import useActions from '../hooks/useActions';

export const log = (e) => (dispatch) => dispatch({
  type: error.types.log,
  payload: e,
});

export const dismiss = () => (dispatch) => dispatch({
  type: error.types.dismiss,
});

export default () => useActions({
  log,
  dismiss,
});
