export default (selectedClassCategories) => {
  const noClassCategoriesSelected = Object
    .values(selectedClassCategories)
    .reduce((out, selected) => out && !selected, true);

  return ({ product }) => (
    product === null
      || product.class_category === null
      || noClassCategoriesSelected
      || selectedClassCategories[product.class_category]
  );
};
