import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import useAuthentication from '../hooks/useAuthentication';
import userActions from '../actions/userActions';
import useBookingEngine from '../hooks/useBookingEngine';

export default ({ children }) => {
  const [done, setDone] = useState(false);
  const {
    silentLogin, access_token: accessToken, profile,
  } = useAuthentication();
  const bookingEngine = useBookingEngine();
  const { parseEligibility, getUserGym, getServerTime } = userActions(bookingEngine);

  const onAccessToken = async (token) => {
    if (token) {
      const eligibility = parseEligibility(profile);
      if (eligibility.gymEligibility && eligibility.eligibility.A_GYM_Site) {
        await getUserGym(eligibility.eligibility.A_GYM_Site);
      }
      setDone(true);
    } else if (token === null) {
      setDone(true);
    }
  };

  const configureServerTime = async () => {
    // We construct a date object with the server date and device time
    const currentServerTime = new Date(await getServerTime());
    moment.now = () => {
      const deviceDate = new Date();
      deviceDate.setMonth(currentServerTime.getMonth());
      deviceDate.setDate(currentServerTime.getDate());
      deviceDate.setFullYear(currentServerTime.getFullYear());
      return deviceDate.getTime();
    };
  };

  useEffect(() => {
    configureServerTime();
    silentLogin();
  }, []);

  useEffect(() => {
    onAccessToken(accessToken);
  }, [accessToken]);

  return done ? children : <p>Loading...</p>;
};
