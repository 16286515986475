import useActions from '../hooks/useActions';
import hmot from '../reducers/hmot';

export default (bookingEngine) => useActions({
  fetchForLocation: (location, daysToLoad) => async (dispatch) => {
    if (location === null || daysToLoad.length === 0) {
      return;
    }

    const { items } = await bookingEngine.getHMOTTimeslots({
      location,
      from: daysToLoad[0].startOf('day').toISOString(true),
      to: daysToLoad[daysToLoad.length - 1].endOf('day').toISOString(true),
    });

    await dispatch({
      type: hmot.types.reset,
    });

    await dispatch({
      type: hmot.types.addBookableItems,
      payload: items,
    });
  },

  bookHMOTSession: (reservationId) => async (dispatch) => (
    bookingEngine.bookHMOTSession({ reservationId })
  ),
});
