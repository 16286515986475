import useSelect from '../useSelect';

const intensityLevels = [
  {
    name: 'Any Intensity',
    pattern: /^.*$/,
  },
  {
    name: 'Low Intensity',
    pattern: /^Low Intensity$/,
  },
  {
    name: 'High Intensity',
    pattern: /^High Intensity$/,
  },
];

export default () => useSelect(intensityLevels, {
  defaultSelected: 0,
});
