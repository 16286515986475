import React from 'react';
import { useParams } from 'react-router';

import './Beauty.sass';
import useBeauty from '../hooks/useBeauty';
import FlatList from '../components/FlatList';
import BeautyProduct from '../components/BeautyProduct';

export default () => {
  const { gymId } = useParams();
  const { productsByType } = useBeauty(gymId);

  return (
    <div className="page m--beauty">
      <div className="pattern" />
      <div className="header__wrapper">
        <div className="header">
          <div className="h1">Book beauty treatment</div>
        </div>
      </div>
      <span className="divider" />

      <FlatList
        data={productsByType}
        renderItem={([type, products]) => (
          <>
            { type && <p className="category-title">{type}</p> }
            <FlatList
              data={products}
              renderItem={(product, index) => (
                <BeautyProduct
                  key={index}
                  product={product}
                  location={gymId}
                />
              )}
              renderEmpty={() => <p className="no-items-found">No items found</p>}
            />
          </>
        )}
      />
    </div>
  );
};
