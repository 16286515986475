import { useMemo } from 'react';
import ConsultantBookings from '../services/ConsultantBookings';
import errorActions from '../actions/errorActions';

export default () => {
  const { log: logError } = errorActions();

  return useMemo(() => (
    ConsultantBookings.getInstance(logError)
  ), []);
};
