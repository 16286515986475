export default (item, {
  login,
}) => ({
  type: 'LOGGED_OUT',
  name: 'Log in',
  call: async () => {
    await login();
    return null;
  },
});
