import React from 'react';
import { useHistory, useLocation } from 'react-router';
import useConsultantBookingsForm from '../hooks/useConsultantBookingForm';
import FormField from '../components/FormField';
import CTAButton from '../components/CTAButton';
import InitialBookingDisclaimer from '../components/InitialBookingDisclaimer';
import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';

import './ConsultantForm.sass';
import errorActions from '../actions/errorActions';

export default () => {
  const { state } = useLocation();
  const history = useHistory();
  const { log: logError } = errorActions();

  const { detailsForm, toggleTerms, action } = useConsultantBookingsForm(state);

  return (
    <>
      <InitialBookingDisclaimer/>
      <div className="m--consultant-form">      
        <div className="pattern" />
        <div className="header__wrapper mb-40">
          <div className="header">
            <div className="h1">Book consultant</div>
          </div>
        </div>
        <div className="page-title">Your details</div>
        <div className="mb-30">Please fill out your details. We need these to confirm your appointment</div>
        <div className="fields">
          <FormField field={detailsForm.first_name} errorText="Please re-enter the name without accented letters or spaces. For example, Verónica should be Veronica." nameErrorText="Please enter your first name" />
          <FormField field={detailsForm.last_name} errorText="Please re-enter the name without accented letters or spaces. For example, Pérez - Fernández should be Perez-Fernandez." nameErrorText="Please enter your last name" />
          <div className="remaining-fields">
            <FormField field={detailsForm.email} errorText="Please enter your email address" />
            <FormField field={detailsForm.addresslookup} errorText="Please enter your address" />
            <div className="gender-field__wrapper">
              <FormField field={detailsForm.gender} errorText="Please select your gender" />
            </div>
            <FormField field={detailsForm.date_of_birth} errorText="Please select your date of birth" />
            <p>
              If you are under 18 or booking on behalf of someone who is under 18,
              please call 0345 602 9262 as we cannot take paediatric bookings online.
            </p>
            <FormField field={detailsForm.phone_number} errorText="Please enter your phone number" />
            <div>
              <FormField field={detailsForm.payment} direction="vertical" errorText="Please tell us how you&apos;ll pay for the consultation" />
              {
                detailsForm.payment.value && (
                  detailsForm.payment.value === 'insurance'
                    ? (
                      <>
                        <div className="mt-20">
                          <FormField field={detailsForm.insurer} />
                        </div>
                        {
                          detailsForm.insurer.value === 'other'
                          && (
                            <div className="mt-20">
                              <FormField field={detailsForm.insurer_name} />
                            </div>
                          )
                        }
                        <div className="mt-20">
                          <FormField field={detailsForm.policy_number} />
                        </div>
                        <div className="mt-20">
                          <FormField field={detailsForm.authorization_code} />
                        </div>
                        <div className="mt-20">
                          <p>
                            Please note that without an authorisation code you are liable for
                            the full self-pay cost of your initial appointment.
                          </p>
                        </div>
                      </>
                    )
                    : (
                      <p className="cash-or-credit mt-20">We may contact you in advance of the appointment to take payment, otherwise you&apos;ll receive an invoice after your consultation. Please note that if you do not attend your booked appointment you will be liable for the full self-pay cost.</p>
                    )
                )
            }
            </div>
            <div>
              <FormField field={detailsForm.has_gp_referral} direction="horizontal" errorText="Please tell us whether you have a GP referral or not" />
              {
                detailsForm.has_gp_referral.value
                && detailsForm.has_gp_referral.value === 'yes'
                && (
                  <p className="mt-20">
                    Please bring your referral letter to your appointment. Before your appointment,
                    we&apos;ll contact you to ask for any relevant documents you have, such as
                    pathology and imaging reports.
                  </p>
                )
              }
              {
                detailsForm.has_gp_referral.value
                && (
                  <div>
                    <p className="mt-20">
                      If you have any of the following symptoms, please do not continue
                      your online booking and contact your GP or NHS 999/111.
                    </p>
                    <ul className="mt-20">
                      <li>Sudden onset chest pain/heaviness in your chest</li>
                      <li>Heart palpitations with difficulty in breathing</li>
                      <li>Sudden difficulty in breathing</li>
                      <li>Seizure/fitting affecting consciousness and ongoing symptoms</li>
                      <li>
                        Severe allergic reaction with breathing difficulties or swelling
                        of the mouth
                      </li>
                      <li>
                        Sudden onset neurological changes such as weakness, slurring of speech,
                        drowsiness, sudden onset severe headache in the back of the head or
                        neck stiffness with fever
                      </li>
                      <li>Uncontrollable bleeding or a significant injury/accident</li>
                      <li>Pregnancy-related concerns</li>
                      <li>
                        Feeling suicidal – If you are considering harming yourself
                        please contact NHS111 or the Samaritans 116 123
                      </li>
                    </ul>
                  </div>
                )
              }
            </div>
            <div>
              <FormField field={detailsForm.send_discharge_details_to_gp} direction="horizontal" errorText="Please tell us whether you wish to have your discharge details to go to your GP" />
              {detailsForm.send_discharge_details_to_gp.value === 'yes' && (
                <div className="mt-40">
                  <FormField field={detailsForm.gp_details} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="communication__wrapper mb-40">
          <p className="mb-40">To continue to receive communications from Nuffield Health about our exclusive offers, products and services, then please tell us how you&apos;d like to be contacted by ticking the relevant boxes below:</p>
          <div className="mb-40">
            <FormField field={detailsForm.communication_preferences} />
          </div>
          <p className="mb-30">
            On occasion Nuffield Health may contact you with pertinent service information
            in regards to services we provide. Note that you can either amend or withdraw
            your consent at any time.
          </p>
          <p>
            For information about where your personal data may be processed, how it may be
            processed and for details of our Data Protection Officer, please see our Privacy Policy.
          </p>
        </div>


        <div className="custom-checkbox__wrapper">
          <input className="custom-checkbox__value" id="toc" type="checkbox" onChange={toggleTerms} />
          <label className="custom-checkbox__label" htmlFor="toc">
            <span className="custom-checkbox__faux">
              <Checkmark />
            </span>
            <span>
            I agree to the
              {' '}
            </span>
            <a target="_blank" rel="noreferrer noopener" href="https://www.nuffieldhealth.com/terms/nuffield-health-website-terms-and-conditions">terms and conditions</a>
            <span>
              {' '}
            and
              {' '}
            </span>
            <a target="_blank" rel="noreferrer noopener" href="https://www.nuffieldhealth.com/privacy">privacy policy</a>
          </label>
        </div>
        <div className="ctas__wrapper mt-40">
          <CTAButton
            action={action}
            onAction={(result) => {
              if (result.statusCode === 'true') {
                history.replace(`/consultants/${state.consultant.gmcCode}/booked/`, state);
              } else {
                logError({
                  error: 'CUSTOM',
                  text: 'Sorry but we are unable to process your booking online. Please call 0345 602 9262 to complete your booking.',
                });
              }
            }}
          />
          <CTAButton
            className="back-btn"
            action={{
              call: async () => history.goBack(),
              name: 'Back',
              type: 'BACK',
            }}
          />
        </div>
      </div>
    </>
  );
};
