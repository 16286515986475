import createReducer from '../utils/createReducer';

const INITIAL = null;

export default createReducer('auth', INITIAL, {
  set: (state, payload) => {
    if (payload === null) {
      return {
        access_token: null,
      };
    }
    return (payload || {});
  },
});
