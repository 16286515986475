import React, { useRef } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import FlatList from '../components/FlatList';
import useGymClasses from '../hooks/useGymClasses';
import GymClassList from '../components/GymClassList';
import DaysToggle from '../components/DaysToggle';
import SelectBox from '../components/SelectBox';
import { ReactComponent as Arrow } from '../static/img/arrow-alt.svg';
import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';
import './GymClasses.sass';
import ClassStatus from '../components/ClassStatus';

export default () => {
  const filterToggleRef = useRef(null);
  const { gymId } = useParams();

  const {
    days,
    filteredItems,
    onlyWithPlacesLeft,
    practitioners,
    setOnlyWithPlacesLeft,
    timeOfDay,
    intensityLevels,
    classCategories,
  } = useGymClasses(gymId);

  const { gymEligibility } = useSelector(({ user }) => user);
  const toggleFilters = () => {
    filterToggleRef.current.classList.toggle('m--open');
  };

  return (
    <>
      <div className="page m--gymclasses">
        <div className="pattern-gym" />
        <div className="gym-header__wrapper">
          <div className="gym-header">
            <div className="h1">Book classes</div>
          </div>
        </div>
        <button type="button" className="filter-toggle__button" ref={filterToggleRef} onClick={toggleFilters}>
          Filter classes
          <Arrow />
        </button>
        <div className="filters__wrapper">
          <div className="boolean-filters__wrapper">
            <div className="custom-checkbox__wrapper">
              <input
                type="checkbox"
                onChange={() => setOnlyWithPlacesLeft(!onlyWithPlacesLeft)}
                checked={onlyWithPlacesLeft}
                id="placesLeft"
                className="custom-checkbox__value"
              />
              <label className="custom-checkbox__label" htmlFor="placesLeft">
                <span className="custom-checkbox__faux">
                  <Checkmark />
                </span>
                Classes with spaces
              </label>
            </div>
          </div>
          <div className="selectbox-filters_wrapper">
            <SelectBox title="Instructors" type="checkbox" data={practitioners}>
              <div className="custom-select__list">
                <FlatList
                  data={practitioners}
                  renderItem={({
                    item: { sfid, name }, toggle, selected,
                  }) => (
                    <div className="custom-select__li" key={sfid}>
                      <input
                        type="checkbox"
                        id={sfid}
                        className="custom-checkbox__value"
                        onChange={toggle}
                        checked={selected}
                      />
                      <label className="custom-select__label" htmlFor={sfid}>
                        <span className="custom-checkbox__faux">
                          <Checkmark />
                        </span>
                        {name}
                      </label>
                    </div>
                  )}
                />
              </div>
            </SelectBox>
            <SelectBox title="Time of day" type="radio" data={timeOfDay}>
              <div className="custom-select__list">
                <FlatList
                  data={timeOfDay}
                  renderItem={({ item: { name, from, to }, selected, select }, i) => (
                    <div className="custom-select__li" key={i}>
                      <input
                        type="radio"
                        name="timeOfDay"
                        id={`timeOfDay${i}`}
                        className="custom-radio__value"
                        checked={selected}
                        onChange={select}
                      />
                      <label className="custom-select__label" htmlFor={`timeOfDay${i}`}>
                        {name}
                        {i > 0 && (
                          ` (${moment(from, 'HH:mm').format('ha')} - ${moment(to, 'HH:mm').format('ha')})`
                        )}
                      </label>
                    </div>
                  )}
                />
              </div>
            </SelectBox>
            <SelectBox title="Class categories" type="checkbox" data={classCategories}>
              <div className="custom-select__list">
                <FlatList
                  data={classCategories}
                  renderItem={({ item: { name }, selected, toggle }, i) => (
                    <div className="custom-select__li" key={i}>
                      <input
                        type="checkbox"
                        name="classCategory"
                        id={`classCategory${i}`}
                        className="custom-checkbox__value"
                        checked={selected}
                        onChange={toggle}
                      />
                      <label className="custom-select__label" htmlFor={`classCategory${i}`}>
                        <span className="custom-checkbox__faux">
                          <Checkmark />
                        </span>
                        {name}
                      </label>
                    </div>
                  )}
                />
              </div>
            </SelectBox>
            <SelectBox title="Intensity Level" type="radio" data={intensityLevels}>
              <div className="custom-select__list">
                <FlatList
                  data={intensityLevels}
                  renderItem={({ item: { name }, selected, select }, i) => (
                    <div className="custom-select__li" key={i}>
                      <input
                        type="radio"
                        name="intensityLevel"
                        id={`intensityLevel${i}`}
                        className="custom-radio__value"
                        checked={selected}
                        onChange={select}
                      />
                      <label className="custom-select__label" htmlFor={`intensityLevel${i}`}>{name}</label>
                    </div>
                  )}
                />
              </div>
            </SelectBox>
          </div>
        </div>
        <DaysToggle days={days} />
        <span className="divider" />
        <div className="showing-date">
          Showing:&nbsp;
          <strong>
            {
              days.map((day) => (day.selected ? day.item.date.format('dddd D MMMM') : ''))
            }
          </strong>
        </div>
        <GymClassList
          gymClasses={filteredItems}
          renderDetails={(item) => (
            <ClassStatus item={item} />
          )}
        />
      </div>
    </>
  );
};
