import React, { useEffect, useMemo, useState } from 'react';
import useBookingEngine from '../hooks/useBookingEngine';
import useConsultantBookingsApi from '../hooks/useConsultantBookingsApi';

import './GPLookup.sass';

const REGEX_POSTCODE = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/;

export default function GPLookup({ setValue, id }) {
  const [postcode, setPostcode] = useState('');
  const [loadingPractices, setLoadingPractices] = useState(false);
  const [practiceList, setPracticeList] = useState(null);
  const [practice, setPractice] = useState('');
  const [loadingGPs, setLoadingGPs] = useState(false);
  const [gpList, setGpList] = useState(null);
  const [gpCode, setGpCode] = useState('');
  const [gpFreeText, setGpFreeText] = useState(null);

  const bookingEngine = useBookingEngine();
  const consultantBookingsApi = useConsultantBookingsApi();

  const validPostcode = useMemo(
    () => (postcode && postcode.match(REGEX_POSTCODE) ? postcode : null),
    [postcode],
  );
  const selectedGp = useMemo(
    () => {
      if (gpList) {
        if (gpCode === 1) {
          return 'unknown';
        }
        if (gpCode === '???') {
          return gpList[0];
        }
        return (gpList || []).find((gp) => gp.code === gpCode);
      }
      return null;
    },
    [gpList, gpCode],
  );

  useEffect(() => {
    setValue(selectedGp);
  }, [selectedGp]);

  useEffect(() => {
    setValue({ freeText: gpFreeText });
  }, [gpFreeText]);

  useEffect(() => {
    setPracticeList(null);
    setPractice('');
    setGpList(null);
    if (validPostcode) {
      setLoadingPractices(true);
      bookingEngine.consultantServiceSearch({ postcode: validPostcode }).then(({ results }) => {
        setPracticeList(results);
        setLoadingPractices(false);
      }).catch(() => {
        setPracticeList([]);
        setLoadingPractices(false);
      });
    }
  }, [validPostcode]);

  useEffect(() => {
    setGpList(null);
    setGpCode('');
    if (practice && practice !== 'unknown') {
      setLoadingGPs(true);

      consultantBookingsApi.gpLookup({ postcode: practice.substring(0, practice.indexOf('|')) }).then((data) => {
        setGpList(data.gpList);
        setLoadingGPs(false);
      }).catch(() => {
        setGpList([]);
        setLoadingGPs(false);
      });
    }
  }, [practice]);

  return (
    <>
      <p>
        Use the postcode field to search for practices in your area.
      </p>
      <div className="zipcode-field__wrapper mt-20">
        <div className={`field ${(postcode && !validPostcode) ? 'field--invalid' : ''}`}>
          <label
            className="form-field__label"
            htmlFor={`gplookup-postcode-${id}`}
          >
            Postcode*
          </label>
          <input
            type="text"
            id={`gplookup-postcode-${id}`}
            value={postcode}
            placeholder="Enter postcode to search"
            className={`text__input ${validPostcode ? 'invalid' : ''}`}
            onInput={(e) => setPostcode(e.target.value)}
            onChange={(e) => setPostcode(e.target.value.toUpperCase())}
          />
        </div>
        {loadingPractices && (
          <div className="mt-20">
            <div className="gplookup__spinner" />
            Searching for practices...
          </div>
        )}
        {practiceList !== null && practiceList.length > 0 && (
          <div className="mt-20">
            <label
              className="form-field__label"
              htmlFor={`gplookup-practice-${id}`}
            >
              Practice
            </label>
            <select
              name={`gplookup-practice-${id}`}
              id={`gplookup-practice-${id}`}
              className="select__input"
              value={practice}
              onChange={(e) => setPractice(e.target.value)}
            >
              <option value="" disabled>Select your practice</option>
              {practiceList.map((gp) => (
                <option value={`${gp.postcode}|${gp.organisation}`} key={gp.organisation}>
                  {`${gp.organisation} (${gp.city}, ${gp.postcode})`}
                </option>
              ))}
              <option value="unknown">My GP Practice is not listed</option>
            </select>
          </div>
        )}
        {loadingGPs && (
          <div className="mt-20">
            <p>
              <span className="gplookup__spinner" />
              Searching for GPs...
            </p>
          </div>
        )}
        {practiceList !== null && (practiceList.length === 0 || practice === 'unknown') && (
          <div className="mt-20">
            {practice !== 'unknown' && (
            <label className="form-field__label" htmlFor="referral_details">No Practices found</label>
            )}
            <p>Please provide your GP&apos;s details (name, address, telephone number).</p>
            <textarea
              id="gp_free_text"
              name="gp_free_text"
              className="textarea__input"
              autoComplete="on"
              maxLength="150"
              onChange={(e) => setGpFreeText(e.target.value)}
            />
          </div>
        )}
        {gpList !== null && (
          <div className="mt-20">
            <label
              className="form-field__label"
              htmlFor={`gplookup-gp-${id}`}
            >
              GP
            </label>
            {gpList.length === 0 && (
              <select
                name={`gplookup-gp-${id}`}
                id={`gplookup-gp-${id}`}
                className="select__input"
                disabled
              >
                <option>No GPs found</option>
              </select>
            )}
            {gpList.length > 0 && (
              <select
                name={`gplookup-gp-${id}`}
                id={`gplookup-gp-${id}`}
                className="select__input"
                value={gpCode}
                onChange={(e) => setGpCode(e.target.value)}
              >
                <option value="" disabled>Select your GP</option>
                {gpList.map((gp) => (
                  <option value={gp.code} key={gp.code}>
                    {`${gp.description} (${gp.address}, ${gp.postcode})`}
                  </option>
                ))}
                <option value="???">I don&apos;t know</option>
              </select>
            )}
          </div>
        )}
        <br />
        <p>
        We may share information about your treatment with your GP.
        If you do not want us to share this information,
        please tell your consultant at your appointment.
        </p>
        <br />
        <p>
          Please note that there might still be instances where we are legally
          required to share your information with your GP if the practitioners treating
          you believe it to be clinically advisable. You can ask us not to do this,
          in which case we will respect that request if we are legally permitted to do so,
          but you should be aware that it can be potentially very dangerous and/or detrimental
          to your health to deny your GP full information about your medical history,
          and we strongly advise against it.
        </p>
        <p>
          <br />
          You can find out more about how we use your data
          {' '}
          <a target="_blank" rel="noreferrer noopener" href="https://www.nuffieldhealth.com/your-datas-journey-through-nuffield-health-hospitals">here</a>
          .
        </p>
      </div>
    </>
  );
}
