
export default (item, {
  selectEvent,
}) => {
  const {
    sfid,
    is_full: isFull,
  } = item;

  let call = null;
  let name = 'Book';
  let type = 'BOOK';

  if (isFull) {
    name = 'Full';
    type = 'IS_FULL';
  } else {
    call = async () => ({
      type: 'details',
      title: 'Booked',
      ...await selectEvent(sfid),
      reservation: item,
    });
  }

  return {
    name, type, call,
  };
};
