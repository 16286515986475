import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import AddToCalendar from 'react-add-to-calendar';
import moment from 'moment-timezone';
import concatDateTime from '../utils/concatDateTime';
import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';
import { ReactComponent as ArrowRight } from '../static/img/arrow-right.svg';
import InitialBookingDisclaimer from '../components/InitialBookingDisclaimer';
import './ConsultantConfirm.sass';

export default () => {
  const {
    state: {
      consultant, hospital, slotDate, slotTime, slotDuration,
    },
  } = useLocation();

  const mStart = concatDateTime(
    moment(slotDate, 'YYYY-MM-DD'),
    moment(slotTime, 'HH:mm'),
  );

  useEffect(() => {
    if ('parentIFrame' in window) {
      window.parentIFrame.scrollToOffset(0, 0);
    }
  }, []);

  const mEnd = mStart
    .clone()
    .add(slotDuration, 'minutes');

  return (
    <>
      <InitialBookingDisclaimer/>
      <div className="m--consultant-confirm">     
        <div className="pattern" />
        <div className="header__wrapper mb-40">
          <div className="header">
            <div className="h1">Book consultant</div>
          </div>
        </div>
        <div className="booked">
          <span className="icon__wrapper"><Checkmark /></span>
          {' '}
          Booked
        </div>
        <span className="divider" />
        <div className="h2 mt-30 mb-10">Consultation</div>
        <div className="details__wrapper">
          <table className="details">
            <tr>
              <td>Time</td>
              <td>
                {slotTime}
                {' '}
                {mStart.format('Do MMMM YYYY')}
              </td>
            </tr>
            <tr>
              <td>Duration</td>
              <td>
                {slotDuration}
                {' '}
  minutes
              </td>
            </tr>
            <tr>
              <td>Consultant</td>
              <td>
                {consultant.name}
              </td>
            </tr>
            <tr>
              <td>Location</td>
              <td>
                {hospital.hospitalName}
                <br/>
                <br/>
                If we are unable to hold the appointment face to face, we will be in touch to arrange a consultation over the phone.
              </td>
            </tr>
          </table>
          <div>
            <p>We’ll contact you if necessary to collect any additional information. If you need to cancel or change your appointment, please contact us as soon as possible on 0300 123 1903.</p>
            <div className="cancelation-text">
              <div className="add-to-calendar-link__wrapper">
                <AddToCalendar
                  event={{
                    title: `Consultancy from ${consultant.name}`,
                    location: hospital.hospitalName,
                    description: '',
                    startTime: mStart.format(),
                    endTime: mEnd.format(),
                  }}
                />
                <span className="icon__wrapper">
                <ArrowRight />
              </span>
              </div>
            </div>
          </div>
        </div>
        <span className="divider" />
      </div>
    </>
  );
};
