import createReducer from '../utils/createReducer';

const INITIAL_STATE = {};

export default createReducer('beautyProducts', INITIAL_STATE, {
  addProducts: (state, { location, products }) => ({
    ...state,
    [location]: products.reduce((newState, product) => ({
      ...newState,
      [product.sfid]: {
        ...product,
        availabilities: {
          loading: false,
          visible: false,
          items: [],
        },
      },
    }), state[location] || {}),
  }),
  setAvailabilityProperties: (state, { location, sfid, ...properties }) => {
    const product = state[location][sfid];

    product.availabilities = {
      ...product.availabilities,
      ...properties,
    };

    return {
      ...state,
      [location]: {
        ...state[location],
        [sfid]: product,
      },
    };
  },
});
