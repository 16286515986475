import { combineReducers } from 'redux';
import authentication from './authentication';
import gymClasses from './gymClasses';
import modals from './modals';
import facilities from './facilities';
import beautyProducts from './beautyProducts';
import hmot from './hmot';
import myBookings from './myBookings';
import events from './events';
import error from './error';
import user from './user';
import consultants from './consultants';
import gymClassCategories from './gymClassCategories';


const rootReducer = combineReducers({
  authentication,
  gymClasses,
  modals,
  facilities,
  beautyProducts,
  hmot,
  myBookings,
  events,
  user,
  error,
  consultants,
  gymClassCategories,
});

export const RESET_STORE = {
  type: 'RESET_STORE',
};

export default (state, action) => {
  const newState = action.type === RESET_STORE.type
    ? undefined
    : state;

  return rootReducer(newState, action);
};
