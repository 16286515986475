import useActions from '../hooks/useActions';
import beautyProducts from '../reducers/beautyProducts';

const setAvailabilityProperties = (location, productId, { ...properties }) => ({
  type: beautyProducts.types.setAvailabilityProperties,
  payload: {
    location,
    sfid: productId,
    ...properties,
  },
});

export default (bookingEngine) => useActions({
  fetchBeautyProducts: (location, reload = false) => async (dispatch) => {
    const { items } = await bookingEngine.beautyProducts({ location });

    if (reload) {
      dispatch({
        type: beautyProducts.types.reset,
      });
    }

    dispatch({
      type: beautyProducts.types.addProducts,
      payload: {
        location,
        products: items,
      },
    });
  },

  fetchAvailabilities: (location, productId, start, end) => async (dispatch) => {
    dispatch(setAvailabilityProperties(location, productId, {
      loading: true,
    }));

    const response = await bookingEngine.beautyProductAvailability({
      location,
      product: productId,
      from: start,
      to: end,
    });

    dispatch(setAvailabilityProperties(location, productId, {
      loading: false,
      items: response.items,
    }));
  },

  openAvailabilities: (location, productId) => async (dispatch) => {
    dispatch(setAvailabilityProperties(location, productId, {
      visible: true,
    }));
  },

  closeAvailabilities: (location, productId) => async (dispatch) => {
    dispatch(setAvailabilityProperties(location, productId, {
      visible: false,
    }));
  },

  bookBeautyProduct: (bookingProductId, resourceId, staffId, start, end) => async (dispatch) => bookingEngine.bookBeautyProduct({
    bookingProductId,
    staffId,
    resourceId,
    from: start,
    to: end,
  }),
});
