import React from 'react';
import { useHistory, useParams } from 'react-router';
import moment from 'moment-timezone';
import useConsultantClinicDays from '../hooks/useConsultantBookings';
import DaysToggle from '../components/DaysToggle';
import TimeslotToggle from '../components/TimeslotToggle';
import FlatList from '../components/FlatList';
import purifyObject from '../utils/purifyObject';
import CTAButton from '../components/CTAButton';
import InitialBookingDisclaimer from '../components/InitialBookingDisclaimer';

import { ReactComponent as PersonPlus } from '../static/img/person-plus.svg';
import { ReactComponent as Location } from '../static/img/location.svg';
import { ReactComponent as Pound } from '../static/img/pound.svg';

import './Consultant.sass';

export default () => {
  const { gmcCode } = useParams();
  const {
    loaded, selectedSlots, bookableSlots,  clinicDays, slots,
  } = useConsultantClinicDays(gmcCode);
  const history = useHistory();

  return loaded
    ? (
      <>
        <InitialBookingDisclaimer/>      
        <div className="m--consultant">
        { bookableSlots == true ? 
          <div>         
          <div className="pattern" />
          <div className="header__wrapper mb-40">
            <div className="header">          
              <div className="h1">Book consultant</div>
            </div>
          </div>
          <div className="datepicker">
            <DaysToggle
              days={clinicDays.selectableDays}
              selectedDay={clinicDays.selectedDay}
              actions={clinicDays.actions}
              showMonth
            />
            <TimeslotToggle timeslots={slots} />
          </div>
          <FlatList
            data={selectedSlots}
            renderItem={(slot) => {
              const {
                slotId, slotTime, slotDuration, consultant, hospital,
              } = slot;
              const mSlotTime = moment(slotTime, 'HH:mm');
              const mSlotEndTime = mSlotTime.clone().add(slotDuration, 'minutes');

              return (
                <div key={slotId} className="card">
                  <div className="time">
                    {mSlotTime.format('HH:mm')}
                    {' - '}
                    {mSlotEndTime.format('HH:mm')}
                  </div>
                  <div className="details">
                    <div className="product-title bold">Consultation</div>
                    <div className="detail">
                      <div className="person-icon__wrapper">
                        <PersonPlus />
                      </div>
                      <div>{ consultant.name }</div>
                    </div>
                    <div className="detail location__wrapper">
                      <span className="location-icon__wrapper">
                        <Location />
                      </span>
                      <div>{ hospital.hospitalName }</div>
                    </div>
                    <div className="detail">
                      <span className="location-icon__wrapper" />
                      <div>If we are unable to hold the appointment face to face, we will be in touch to arrange a consultation over the phone</div>
                    </div>
                    <div className="detail">
                      <span className="price-icon__wrapper">
                        <Pound />
                      </span>
                      <div>
                        {`${consultant.price}`}
                      </div>
                    </div>
                  </div>
                  <div className="cta__wrapper">
                    <CTAButton
                      action={{
                        name: 'Select',
                        call: async () => {
                          history.push(`/consultants/${gmcCode}/details/`, purifyObject(slot));
                        },
                        type: 'BOOK',
                      }}
                    />
                  </div>
                </div>
              );
            }}
          />
          </div>
          :
        <div className="m--consultant">
          <div className="pattern" />
            <div className="header__wrapper mb-40">
              <div className="header">
                <div className="h1">Sorry, there are no appointments available online for the next 90 days. Please choose another consultant or call 0300 123 1903</div>
              </div>
            </div>
          </div>
        } 
        </div>
      </>
    )
    : <p>Loading...</p>;
};
