import React from 'react';
import './InitialBookingDisclaimer.sass'

export default function InitialBookingDisclaimer(){
  return (
    <>
      <p className="initial-booking-disclaimer">        
        Note: Online booking is for initial appointments only. Follow-up appointments must be booked directly with the consultant or hospital. These appointments are not for emergencies. If you need urgent support, please call 111.        
      </p>
    </>
  );
};
