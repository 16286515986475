import authentication from '../reducers/authentication';
import useActions from '../hooks/useActions';

export default (auth) => useActions({
  silentLogin: () => async (dispatch) => {
    const payload = await auth.silentLogin();

    await dispatch({
      type: authentication.types.set,
      payload,
    });

    return payload;
  },

  login: () => async (dispatch) => {
    const payload = await auth.login();

    if (payload) {
      await dispatch({
        type: authentication.types.set,
        payload,
      });

      return payload;
    }
  },

  logout: () => async (dispatch) => {
    await auth.logout();

    await dispatch({
      type: authentication.types.set,
      payload: {},
    });
  },

  refresh: () => async (dispatch) => {
    const payload = await auth.refresh();

    if (payload) {
      await dispatch({
        type: authentication.types.set,
        payload,
      });
    }

    return payload;
  },
});
