import moment from 'moment-timezone';
import { BOOKING_STATES, CANCELABLE_STATES } from '../../enums/booking';

const parseBookOrWaitlistResults = ({ status, ...results }, item, leaveWaitlistTime, cancellationTime) => {
  const booked = status === BOOKING_STATES.booked;

  return ({
    type: 'details',
    title: booked
      ? 'You\'re Booked!'
      : 'You\'re on the Waitlist!',
    ...results,
    reservation: item,
    cancelTime: (booked ? cancellationTime : leaveWaitlistTime) || 0,
  });
};

export default (item, {
  book,
  cancel,
  bookToWaitlist,
  removeFromWaitlist,
}, gymEligibility) => {
  const {
    my_booking: myBooking,
    title,
    sfid,
    product,
    room,
    from_date: fromDate,
    has_waitlist: hasWaitlist,
    cancellation_time: cancellationTime,
    leave_wait_list_time: leaveWaitlistTime,
    is_full: isFull,
  } = item;

  // Wrapper around the book event to push data to Tag Manager.
  const bookWrapper = (async () => {
    const bookingResult = await book(sfid);
    global.TagManager.pushEvent({
      bookingSuccess: true,
      productStart: fromDate,
      resourceId: room.facility.sfid,
      locationName: room.facility.name,
      reservationId: sfid,
      reservationName: product.name,
      type: product.class_category,
    });
    return bookingResult;
  });

  let call = null;
  let type = null;
  let name = '';

  if (!gymEligibility) {
    return null;
  }

  if (myBooking && CANCELABLE_STATES.includes(myBooking.status.toUpperCase())) {
    const status = myBooking.status.toUpperCase();

    if (status === BOOKING_STATES.booked) {
      name = 'Cancel class';
      type = 'CANCEL_BOOKING';
      call = () => (
        moment(fromDate)
          .subtract(cancellationTime || 0, 'hour')
          .isBefore()
          ? {
            type: 'confirm',
            header: 'Cancel your booking?',
            title: 'Sorry it\'s too late to cancel. Please be aware you will be marked as non-attended if you do not attend this class.',
            confirm: {
              name: 'Ok I\'ll attend',
              call: async () => {},
              type: 'OK',
            },
          }
          : {
            type: 'confirm',
            header: 'Cancel your booking?',
            title: `Are you sure you want to cancel ${title} class on ${moment(fromDate).format('dddd Do MMMM HH:mm')}?`,
            confirm: {
              call: async () => cancel(sfid, myBooking.sfid || myBooking.heroku_id),
              name: 'Cancel and continue',
              type: 'CANCEL',
            },
            deny: {
              name: 'No, go back',
              call: async () => {},
              type: 'BACK',
            },
          }
      );
    } else if (status === BOOKING_STATES.waitlist) {
      name = 'Leave waitlist?';
      type = 'LEAVE_WAITLIST';
      call = () => (
        moment(fromDate)
          .subtract(leaveWaitlistTime || 0, 'hour')
          .isBefore()
          ? {
            type: 'confirm',
            header: 'Leave waitlist?',
            title: 'Sorry it’s too late to leave the waitlist. You must amend any bookings at least 1 hour before the class starts to give others the opportunity to attend.',
            confirm: {
              name: 'Ok',
              call: async () => {},
              type: 'OK',
            },
          }
          : {
            type: 'confirm',
            header: 'Leave waitlist?',
            title: `Are you sure you want to leave the waitlist for ${title} class on ${moment(fromDate).format('dddd Do MMMM HH:mm')}?`,
            confirm: {
              call: async () => removeFromWaitlist(sfid, myBooking.sfid || myBooking.heroku_id),
              name: 'Cancel and continue',
              type: 'CANCEL',
            },
            deny: {
              name: 'No, go back',
              call: async () => {},
              type: 'BACK',
            },
          }
      );
    }
  } else if (
    myBooking && myBooking.status.toUpperCase() === BOOKING_STATES.pending
  ) {
    name = 'Loading...';
  } else if (
    // Check if the class is on the 8th day from now
    moment(fromDate).isSame(moment().add(8, 'days'), 'date')
    && moment().tz('Europe/London').isBefore(moment().tz('Europe/London').set({
      hour: 7, minute: 0, second: 0, millisecond: 0,
    }))
  ) {
    name = 'Available to book from 07:00';
    type = 'TO_EARLY';
  } else if (isFull) {
    name = 'Class Full';
    type = 'IS_FULL';
  } else if (hasWaitlist) {
    name = 'Join Waitlist';
    type = 'JOIN_WAITLIST';
    call = async () => {
      const bookingResults = await bookToWaitlist(sfid);
      return parseBookOrWaitlistResults(bookingResults, item, leaveWaitlistTime, cancellationTime);
    };
  } else {
    name = 'Book';
    type = 'BOOK';
    call = async () => {
      const bookingResults = await bookWrapper();
      return parseBookOrWaitlistResults(bookingResults, item, leaveWaitlistTime, cancellationTime);
    };
  }

  return { name, type, call };
};
