import { useMemo } from 'react';
import BookingEngine from '../services/BookingEngine';
import useAuthentication from './useAuthentication';
import errorActions from '../actions/errorActions';

export default () => {
  const { refresh, access_token: accessToken } = useAuthentication();
  const { log: logError } = errorActions();

  return useMemo(() => (
    BookingEngine.getInstance(accessToken, refresh, logError)
  ), [accessToken]);
};
