import React, { useState } from 'react';
import moment from 'moment-timezone';
import { ReactComponent as ArrowAlt } from '../static/img/arrow-alt.svg';
import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';

import './FormField.sass';
import useValidation from '../hooks/useValidation';
import AddressLookup from './AddressLookup';
import GPLookup from './GPLookup';

export default (
  {
    field: {
      id,
      type,
      value,
      placeholder,
      setValue,
      label,
      choices,
      required,
      valid,
      setValid,
      validator,
      autoComplete,
      isValid,
      maxlength,
    },
    errorText,
    nameErrorText,
    direction,
  },
) => {
  const [touched, setTouched] = useState(false);
  useValidation({
    setValid, isValid, value, validator, required,
  });

  return (
    <div className={`field ${!valid && touched ? 'field--invalid' : ''} ${!value && touched ? 'field--invalid' : ''}`}>
      { label && (
        <label className="form-field__label" htmlFor={id}>
          { typeof label === 'function' ? label() : label }
          {required && (
            <sup>*</sup>
          )}
        </label>
      )}
      {(() => {
        switch (type) {
          case 'select':
            return (
              <div className="select-field__wrapper">
                <select
                  name={id}
                  id={id}
                  className="select__input"
                  onChange={({ target }) => setValue(target.value)}
                  defaultValue={value}
                >
                  {placeholder && (
                    <option value="" disabled>
                      {placeholder}
                    </option>
                  )}
                  {
                    choices.map(([choice, name]) => (
                      <option value={choice} key={choice}>{ name }</option>
                    ))
                  }
                </select>
              </div>
            );
          case 'custom_date':
            return (
              <div className="date-field__outer-wrapper">
                <div className="date-field__inner-wrapper">
                  <input
                    id={id}
                    type="number"
                    className="text__input m--day"
                    maxLength="2"
                    min="1"
                    max="31"
                    autoComplete={autoComplete || 'on'}
                    defaultValue={value.day}
                    onChange={({ target }) => setValue({
                      ...value,
                      day: target.value,
                    })}
                    placeholder="DD"
                  />
                  <input
                    id={id}
                    type="number"
                    className="text__input m--month"
                    maxLength="2"
                    min="1"
                    max="12"
                    autoComplete={autoComplete || 'on'}
                    defaultValue={value.month}
                    onChange={({ target }) => setValue({
                      ...value,
                      month: target.value,
                    })}
                    placeholder="MM"
                  />
                  <input
                    id={id}
                    type="number"
                    className="text__input m--year"
                    maxLength="4"
                    min="1900"
                    max={moment().format('YYYY')}
                    autoComplete={autoComplete || 'on'}
                    defaultValue={value.year}
                    onChange={({ target }) => setValue({
                      ...value,
                      year: target.value,
                    })}
                    placeholder="YYYY"
                  />
                </div>
              </div>
            );
          case 'radiogroup':
            return (
              <div className={`radio-group m--${direction}`}>
                {
                  choices.map(([choice, name]) => (
                    <div
                      className="custom-radio__wrapper"
                      key={choice}
                    >
                      <input
                        type="radio"
                        className="custom-radio__value"
                        value={choice}
                        id={`radio-${id}-${choice}`}
                        name={id}
                        checked={value === choice}
                        required={required}
                        onChange={({ target }) => setValue(target.value)}
                        defaultChecked={value}
                      />
                      <label className="custom-radio__label" htmlFor={`radio-${id}-${choice}`}>
                        <span className="custom-radio__faux" />
                        { name }
                      </label>
                    </div>
                  ))
                }
              </div>
            );
          case 'checkboxgroup':
            return (
              <div className="checkbox-group">
                {
                  choices.map(([choice, name]) => (
                    <div
                      className="custom-checkbox__wrapper"
                      key={choice}
                    >
                      <input
                        type="checkbox"
                        value={choice}
                        id={`checkbox_${id}_${choice}`}
                        name={id}
                        className="custom-checkbox__value"
                        onChange={({ target }) => setValue({
                          ...value,
                          [target.value]: !value[target.value],
                        })}
                        defaultChecked={value[choice]}
                      />
                      <label className="custom-checkbox__label" htmlFor={`checkbox_${id}_${choice}`}>
                        <span className="custom-checkbox__faux">
                          <Checkmark />
                        </span>
                        {name}
                      </label>
                    </div>
                  ))
                }
              </div>
            );
          case 'textarea':
            return (
              <textarea
                id={id}
                name={id}
                className="textarea__input"
                onChange={({ target }) => setValue(target.value)}
                autoComplete={autoComplete || 'on'}
                placeholder={placeholder}
                required={required}
                defaultValue={value}
                onBlur={() => setTouched(true)}
                maxLength={maxlength || undefined}
              />
            );
          case 'addresslookup':
            return (
              <AddressLookup id={id} setValue={setValue}  />
            );
            case 'gplookup':
              return (
                <GPLookup id={id} setValue={setValue} />
              );
          default:
            return (
              <input
                id={id}
                name={id}
                type={type}
                className="text__input"
                onChange={({ target }) => setValue(target.value)}
                placeholder={placeholder}
                required={required}
                autoComplete={autoComplete || 'on'}
                defaultValue={value}
                onBlur={() => setTouched(true)}
              />
            );
        }
      })()}
      {!valid && touched && (
        <div className="field-error">
          {errorText}
        </div>
      )}
      {!value && touched && (
        <div className="field-error">
          {nameErrorText}
        </div>
      )}
    </div>
  );
};
