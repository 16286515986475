import React, { useCallback, useState } from 'react';
import moment from 'moment-timezone';
import useForm from '../useForm';
import consultantActions from '../../actions/consultantActions';
import useConsultantBookingsApi from '../useConsultantBookingsApi';
import { VALIDATORS } from '../../enums/validators';
import validatePhoneNumber from '../../utils/validatePhoneNumber';
import { INSURERS } from '../../enums/insurers';
import validateName from '../../utils/validateName';

const DETAILS_FORM = {
  first_name: {
    type: 'text',
    label: 'First name',
    required: true,
    validator: VALIDATORS.custom,
    isValid: (value) => validateName(value),
  },
  last_name: {
    type: 'text',
    label: 'Last name',
    required: true,
    validator: VALIDATORS.custom,
    isValid: (value) => validateName(value),
  },
  email: {
    type: 'email',
    label: 'Email',
    required: true,
  },
  gender: {
    type: 'select',
    label: 'Gender',
    required: true,
    choices: [
      ['female', 'Female'],
      ['male', 'Male'],
      ['other', 'Other / Prefer not to say'],
    ],
    placeholder: 'Please select your gender',
    defaultValue: '',
  },
  date_of_birth: {
    type: 'custom_date',
    label: 'Date of birth',
    required: true,
    validator: VALIDATORS.custom,
    isValid: (date) => {
      const mDate = moment(
        `${date.year}-${date.month}-${date.day}`,
        'YYYY-MM-DD',
      );

      return Boolean(date.day)
        && Boolean(date.month)
        && Boolean(date.year)
        && mDate.isValid();
    },
    defaultValue: {
      day: undefined,
      month: undefined,
      year: undefined,
    },
  },
  phone_number: {
    type: 'tel',
    label: 'Phone number',
    required: true,
    validator: VALIDATORS.custom,
    isValid: (value) => Boolean(value) && validatePhoneNumber(value) && value.indexOf('0') === 0,
  },
  has_gp_referral: {
    type: 'radiogroup',
    label: 'Do you have a GP referral?',
    required: true,
    choices: [
      ['yes', 'Yes'],
      ['no', 'No'],
    ],
  },
  referral_details: {
    type: 'textarea',
    maxlength: 150,
    label: () => (
      <>
        Please provide your GP&apos;s details (name, address and telephone number).
        If you&apos;re not sure of these details, you can use the
        <a href="https://www.nhs.uk/service-search/find-a-GP/" target="_blank" rel="noreferrer noopener">NHS GP Finder</a>
        . If you aren&apos;t registered with a GP, please tell us below.
      </>
    ),
    required: false,
  },
  send_discharge_details_to_gp: {
    type: 'radiogroup',
    label: 'Do you have an NHS GP?',
    required: true,
    choices: [
      ['yes', 'Yes'],
      ['no', 'No'],
    ],
  },
  gp_details: {
    type: 'gplookup',
    required: false,
    validator: VALIDATORS.custom,
    defaultValue: '',
  },
  addresslookup: {
    type: 'addresslookup',
    label: 'Find your address',
    required: true,
    validator: VALIDATORS.custom,
  },
  payment: {
    type: 'radiogroup',
    label: 'How will you pay for the consultation?',
    required: true,
    choices: [
      ['cash', 'By cash or card'],
      ['insurance', 'I have medical insurance'],
    ],
  },
  insurer: {
    type: 'select',
    label: 'Select insurer',
    required: true,
    validator: VALIDATORS.custom,
    isValid: (insurer, { payment }) => {
      if (payment === 'insurance') {
        return Boolean(insurer);
      }
      return true;
    },
    choices: INSURERS,
    defaultValue: 'Aviva',
  },
  insurer_name: {
    type: 'text',
    label: 'Please specify insurer',
    required: true,
    validator: VALIDATORS.custom,
    isValid: (insurerName, { payment, insurer }) => {
      if (payment === 'insurance' && insurer === 'other') {
        return Boolean(insurerName);
      }
      return true;
    },
  },
  policy_number: {
    type: 'text',
    label: 'Policy number',
    required: true,
    validator: VALIDATORS.custom,
    isValid: (policyNumber, { payment }) => {
      if (payment === 'insurance') {
        return Boolean(policyNumber);
      }
      return true;
    },
  },
  authorization_code: {
    type: 'text',
    label: 'Authorisation code',
  },
  communication_preferences: {
    type: 'checkboxgroup',
    defaultValue: {
      email: false,
      sms: false,
      phone: false,
    },
    choices: [
      ['email', 'Email'],
      ['sms', 'SMS'],
      ['phone', 'Phone'],
    ],
  },
};

const boolToBinaryString = (value) => Number(value).toString();
const gpDetailsToNotes = (details) => {
  if (details.freeText) {
    return `GP Details: ${details.freeText}`;
  }
  if (details.description) {
    return `GP Details: ${details.description} (${details.address}, ${details.postcode})`;
  }
  return 'GP Details: Online Booking - Please contact patient to confirm details';
};
const gpUnknownReference = 'G9999998/V81999';
const gpDetailsToReference = (details) => {
  if (details.code && !details.freeText) {
    return details.code;
  }
  return gpUnknownReference;
};

const removeTrakcareProblemCharacters = (string, char, replaceChar = '') => string.replaceAll(char, replaceChar);

export default ({ slotId, consultant, hospital }) => {
  const consultantBookings = useConsultantBookingsApi();
  const { bookConsultantSlot } = consultantActions(consultantBookings);

  const [terms, setTerms] = useState(false);
  const toggleTerms = useCallback(() => setTerms(!terms), [terms, setTerms]);

  const detailsForm = useForm(DETAILS_FORM);

  const formComplete = Object
    .values(detailsForm)
    .reduce((valid, field) => valid && (field.touched ? field.valid : field.isValid()));

  const action = {
    call: formComplete && terms
      ? async () => bookConsultantSlot({
        slotId,
        gmcCode: consultant.gmcCode,
        firstName: detailsForm.first_name.value,
        lastName: detailsForm.last_name.value,
        dateOfBirth:
          moment({
            ...detailsForm.date_of_birth.value,
            month: detailsForm.date_of_birth.value.month - 1, // Months are zero-indexed
          })
            .format('YYYY-MM-DD'),
        telephone: detailsForm.phone_number.value,
        address1: removeTrakcareProblemCharacters(detailsForm.addresslookup.value.line_one, ','),
        address2: removeTrakcareProblemCharacters(detailsForm.addresslookup.value.line_two, ','),
        townCity: removeTrakcareProblemCharacters(detailsForm.addresslookup.value.city, ','),
        postCode: detailsForm.addresslookup.value.postal_code,
        email: detailsForm.email.value,
        preAuthorizationNumber: detailsForm.authorization_code.value,
        memberId: detailsForm.policy_number.value,
        gpDetails: {
          gpReference: detailsForm.send_discharge_details_to_gp.value ? gpDetailsToReference(detailsForm.gp_details.value) : gpUnknownReference,
          gpNotes: detailsForm.send_discharge_details_to_gp.value === 'yes' ? removeTrakcareProblemCharacters(gpDetailsToNotes(detailsForm.gp_details.value), '/', ' ') : 'GP Details: Online Booking - Patient does not have an NHS GP',
          gpReferral: boolToBinaryString(detailsForm.has_gp_referral.value === 'yes'),
        },
        gender: detailsForm.gender.value,
        // If the payment method is insurance submit Self Pay to TrakCare
        insuranceProvider: detailsForm.payment.value === 'insurance' ? detailsForm.insurer.value : 'Self Pay',
        insuranceProviderFreeText:
          detailsForm.insurer.value === 'other'
            ? detailsForm.insurer_name.value
            : '',
        hospitalList: hospital.hospitalCode,
        marketingPrefs: {
          emailOptIn: boolToBinaryString(detailsForm.communication_preferences.value.email),
          phoneOptIn: boolToBinaryString(detailsForm.communication_preferences.value.phone),
          textOptIn: boolToBinaryString(detailsForm.communication_preferences.value.sms),
        },
        insurancePayment: boolToBinaryString(detailsForm.payment.value === 'insurance'),
      })
      : null,
    type: 'CONSULTANT',
    name: 'Book',
  };

  return {
    detailsForm,
    toggleTerms,
    action,
  };
};
