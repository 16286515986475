import useSelect from '../useSelect';
import {
  AFTERNOON, ANYTIME, EVENING, MORNING,
} from '../../enums/dayParts';

const timeOfDay = [
  ANYTIME,
  MORNING,
  AFTERNOON,
  EVENING,
];

export default () => useSelect(timeOfDay);
