import { useSelector } from 'react-redux';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import moment from 'moment-timezone';
import { orderBy } from 'lodash';
import useBookingEngine from './useBookingEngine';
import useSelect from './useSelect';
import useDays from './useDays';
import useAuthentication from './useAuthentication';
import myBookingsAction from '../actions/myBookings';
import getLoggedInGymclassAction from './useGymClasses/getLoggedInAction';
import getLoggedInHmotAction from './useHMOT/getLoggedInAction';
import getLoggedInBeautyAction from './useBeautyProductTimeslots/getLoggedInAction';
import getPTLoggedInAction from './usePT/getLoggedInAction';
import productFamilies from '../enums/productFamilies';


const CANCELLED_STATES = [
  'CANCELED',
  'LEFT WAITLIST',
  'USER CANCELLED',
  'ADMIN CANCELLED',
];

const getLoggedInAction = (item, actions) => {
  switch (item.type) {
    case productFamilies.GYM_CLASS:
      return getLoggedInGymclassAction(item, {
        ...actions,
        cancel: (reservationId, reservationContactId) => (
          actions.cancel(reservationId, (bookingEngine) => (
            bookingEngine.cancelGymClass({ reservationContactId })
          ))
        ),
        removeFromWaitlist: (reservationId, reservationContactId) => (
          actions.cancel(reservationId, (bookingEngine) => (
            bookingEngine.cancelGymClass({ reservationContactId })
          ))
        ),
      }, true); // Always set gym eligibility to true when you want to cancel a booking
    case productFamilies.HMOT:
      return getLoggedInHmotAction(item, {
        ...actions,
        cancel: (reservationId, reservationContactId) => (
          actions.cancel(reservationId, (bookingEngine) => (
            bookingEngine.cancelHMOTSession({ reservationContactId })
          ))
        ),
      });
    case productFamilies.BEAUTY:
      return getLoggedInBeautyAction(item, {
        ...actions,
        cancel: (reservationId) => (
          actions.cancel(reservationId, (bookingEngine) => (
            bookingEngine.cancelBeautySession({ reservationId })
          ))
        ),
      });
    case productFamilies.PT:
      return getPTLoggedInAction(item, {
        ...actions,
        cancel: (reservationId, reservationContactId) => (
          actions.cancel(reservationId, (bookingEngine) => (
            bookingEngine.cancelPTSession({ reservationContactId })
          ))
        ),
      });
    default:
      return null;
  }
};

export default ({ groupByDay = true  } = {}) => {
  const { access_token: accessToken } = useAuthentication();
  const bookingEngine = useBookingEngine();
  const actions = myBookingsAction(bookingEngine);
  const { fetchMyBookings, resetMyBookings } = actions;
  const { lastBooking, bookings, nextPage } = useSelector(({ myBookings }) => myBookings);
  const [selectedDay, days, daysActions] = useDays(9);

  const [fetching, setFetching] = useState(false);
  const fetchBookings = useCallback(async (refresh = false) => {
    if (accessToken && !fetching) {
      setFetching(true);
      try {
        if (refresh) {
          daysActions.refresh();
          resetMyBookings();
        }
        await Promise.all([
          fetchMyBookings(false),
          fetchMyBookings(true),
        ]);
      } finally {
        setFetching(false);
      }
    }
  }, [accessToken, fetching]);

  useEffect(() => {
    (async () => {
      if (nextPage.future !== null && lastBooking !== null) {
        const mLastBookingDate = moment(lastBooking.from_date);
        const { item: { date: lastDayInPicker } } = days[8];

        // We only want to fetch the next page if the last booking is within the
        // range of the date picker
        if (mLastBookingDate.isSameOrBefore(lastDayInPicker, 'day')) {
          fetchMyBookings(false, nextPage.future);
        }
      }
    })();
  }, [lastBooking]);

  useEffect(() => {
    fetchBookings();
  }, [accessToken]);

  const [selectedRange, historicActiveToggle] = useSelect([
    {
      name: 'Past',
      filter: ({ to_date: toDate, my_booking: myBooking }) => (
        myBooking
        && (moment(toDate).isBefore()
            || CANCELLED_STATES.includes(myBooking.status.toUpperCase()))
      ),
      sort: (booking) => orderBy(booking, 'from_date', 'desc'),
      map: (item) => ({
        ...item,
        action: null,
      }),
    },
    {
      name: 'Upcoming',
      filter: ({ from_date: fromDate, my_booking: myBooking }) => {
        const mFromDate = moment(fromDate);
        return (
          myBooking
          && !CANCELLED_STATES.includes(myBooking.status.toUpperCase())
          && mFromDate.isAfter()
          && (!groupByDay || mFromDate.isSame(selectedDay.date, 'day'))
        );
      },
      map: (item) => ({
        ...item,
        action: getLoggedInAction(item, actions),
      }),
      sort: (booking) => orderBy(booking, 'from_date', 'asc'),
    },
  ], {
    defaultSelected: 1,
    dependencies: [],
  });

  const myBookings = useMemo(() => selectedRange.sort(
    Object
      .values(bookings)
      .filter(selectedRange.filter)
      .map(selectedRange.map),
  ), [bookings, selectedRange, selectedDay]);

  const loadMore = useMemo(() => ({
    call: nextPage.past
      ? () => fetchMyBookings(true, nextPage.past)
      : null,
    name: 'See more',
    type: 'SEE_MORE',
  }), [nextPage]);

  return {
    myBookings,
    historicActiveToggle,
    days,
    pastBookings: Object.is(selectedRange, historicActiveToggle[0].item),
    loadMore,
    fetchBookings,
    refresh: () => fetchBookings(true),
  };
};
