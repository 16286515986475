import { v4 as uuid4 } from 'uuid';
import genQueryParams from '../utils/genQueryParams';

export default class {
  static getInstance(logError = async () => {}) {
    if (this.instance === undefined) {
      this.instance = new this();
    }

    this.instance.logError = logError;

    return this.instance;
  }

  constructor() {
    this.apimSubscriptionKey = global.APIM_SUBSCRIPTION_KEY;
    this.baseUrl = `${global.APIM_BASE_URL}consultant/`;
  }

  async call(method, endpoint, { params = {}, body = undefined, headers = {} } = {}) {
    const url = `${this.baseUrl}${endpoint}${genQueryParams(params)}`;

    const init = {
      method,
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Ocp-Apim-Subscription-Key': this.apimSubscriptionKey,
        'X-Transaction-Id': uuid4(),
        ...headers,
      },
    };

    if (['PUT', 'POST'].includes(method)) {
      init.body = body === undefined ? '' : JSON.stringify(body);
    }

    const response = await fetch(url, init);
    if (!response.ok) {
      const error = await response.json();
      this.logError(error);
      throw error;
    }

    return response.json();
  }

  async getConsultantClinicDays({
    gmcCode,
    span,
    fromDate,
  }) {
    return this.call('GET', `1.0/clinicdays/gmc/${gmcCode}`, {
      params: {
        span, fromDate,
      },
    });
  }

  async getSlotsAvailability({
    fromDate, gmcCode, hospitalId, sessionDays = 0,
  }) {
    return this.call('GET', '1.0/slots', {
      params: {
        uid: uuid4(),
        fromDate,
        gmcCode,
        hospitalId,
        sessionDays,
      },
    });
  }
  async gpLookup({
    postcode,
  }) {
    if (process.env.REACT_APP_FAKE_GPLOOKUP) {
      if (postcode === 'SE1 4FD' || postcode.substring(0, 3) === 'BA1') {
        const delay = function(ms) {
          return new Promise((resolve, _) => setTimeout(resolve, ms));
        };
        await delay(1500);
        return {
          gpList: [
            {
              code: 'G0107275/G85059',
              description: 'AM Angel',
              address: '4 Tollgate Drive London',
              postcode: 'SE1 4FD',
            },
            {
              code: 'G8764445/G85059',
              description: 'GM MacClean',
              address: '4 Tollgate Drive London',
              postcode: 'SE1 4FD',
            },
          ],
        };
      }
      return {
        gpList: [],
      };
    }
    return this.call('GET', '1.0/gplookup', {
      params: {
        postcode,
      },
    });
  }

  async bookSlot({
    slotId, gmcCode, firstName, lastName, dateOfBirth, telephone,
    address1, address2, townCity, postCode, email, preAuthorizationNumber,
    memberId, gender, insuranceProvider, hospitalList, notes,
    insuranceProviderFreeText, marketingPrefs, gpReferral, gpDetails, insurancePayment,
  }) {
    return this.call('POST', '1.0/book', {
      headers: {
        'Client-Id': 'BMS',
      },
      body: {
        uid: uuid4(),
        slotId,
        gmcCode,
        firstName,
        lastName,
        dateOfBirth,
        telephone,
        address1,
        address2,
        townCity,
        postCode,
        email,
        preAuthorizationNumber,
        memberId,
        notes,
        gender,
        insuranceProvider,
        insuranceProviderFreeText,
        hospitalList,
        marketingPrefs,
        gpReferral,
        gpDetails,
        insurancePayment,
      },
    });
  }
}
