import createReducer from '../utils/createReducer';
import { BOOKING_STATES } from '../enums/booking';

const INITIAL_STATE = {
  nextPage: {
    past: null,
    future: null,
  },
  bookings: {},
  lastBooking: null,
};


const setNextPage = (state, payload) => ({
  ...state,
  nextPage: {
    ...state.nextPage,
    ...payload,
  },
});


const addBooking = (state, {
  reservation, updateCount = false, count, ...booking
}) => {
  const updatedCount = {};
  if (updateCount) {
    const field = count.slice(1);
    const current = state[reservation][field];

    updatedCount[field] = count.charAt(0) === '+'
      ? current + 1
      : current - 1;
  }
  return ({
    ...state,
    bookings: {
      ...state.bookings,
      [reservation]: { ...state.bookings[reservation], ...updatedCount },
    },

  });
};

const setStatus = (state, { reservation, status }) => {
  const newState = { ...state };
  newState.bookings[reservation].my_booking.status = status;
  return newState;
};

const addBookings = (state, bookings) => (
  bookings.reduce((accumulator, reservation) => {
    const newState = { ...accumulator };

    if (!(reservation.sfid in newState.bookings)) {
      newState.bookings[reservation.sfid] = reservation;
    }

    return newState;
  }, state)
);

const cancelBooking = (state, reservationId) => ({
  ...state,
  bookings: {
    ...state.bookings,
    [reservationId]: {
      ...state.bookings[reservationId],
      my_booking: {
        ...state.bookings[reservationId].myBooking,
        status: BOOKING_STATES.userCancelled,
      },
    },
  },
});

const setLastBooking = (state, booking) => ({
  ...state,
  lastBooking: booking,
});

// You would think we can reuse INITIAL_STATE here, but for some reason
// the INITIAL_STATE value changes with the state. Dont know why, worked
// around by manually specifing the initial state.
const reset = (state, payload) => ({
  nextPage: {
    past: null,
    future: null,
  },
  bookings: {},
  lastBooking: null,
});

export default createReducer('myBookings', INITIAL_STATE, {
  setNextPage, addBookings, cancelBooking, setStatus, setLastBooking, reset,
});
