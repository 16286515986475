import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import useDays from '../useDays';
import hmotActions from '../../actions/hmotActions';
import useBookingEngine from '../useBookingEngine';
import useSelect from '../useSelect';
import locationFilter from './filters/locationFilter';
import selectedDayFilter from './filters/selectedDayFilter';
import useAuthentication from '../useAuthentication';
import useGroupedTimeslots from '../useGroupedTimeslots';
import genderFilter from './filters/genderFilter';

const genderChoices = [
  { name: 'No Preference', value: null },
  { name: 'Male', value: 'Male' },
  { name: 'Female', value: 'Female' },
];


export default (location) => {
  const { access_token: accessToken } = useAuthentication();
  const bookingEngine = useBookingEngine();

  const { fetchForLocation, bookHMOTSession } = hmotActions(bookingEngine);
  const [terms, setTerms] = useState(false);
  const toggleTerms = useCallback((value = null) => {
    value != null ? setTerms(value) : setTerms(!terms);
  }, [terms, setTerms]);
  const hmot = useSelector(({ hmot }) => hmot);
  const daysAvailable = useMemo(() => (
    Object
      .values(hmot)
      .reduce((dates, { from_date }) => {
        const date = moment(from_date).format('YYYY-MM-DD');
        return {
          ...dates,
          [date]: null,
        };
      }, {})
  ), [hmot]);


  const [selectedDay, days, actions] = useDays(9, {
    isDisabled: ({ date }) => !daysAvailable.hasOwnProperty(date.format('YYYY-MM-DD')),
    onChange: (firstDay, daysLength, daysList) => {
      if (accessToken) {
        fetchForLocation(location, daysList.map(({ date }) => date));
      }
    },
    dependencies: [location, accessToken, daysAvailable],
  });

  const [selectedGender, genders] = useSelect(genderChoices);

  const timeslots = useMemo(() => {
    if (selectedDay === null) return {};

    return Object
      .values(hmot)
      .filter(({ status }) => status !== 'Canceled')
      .filter(locationFilter(location))
      .filter(selectedDayFilter(selectedDay))
      .filter(({ from_date }) => moment(from_date).isAfter())
      .filter(genderFilter(selectedGender))
      .reduce((_timeslots, { from_date, to_date, ...timeslot }) => {
        const newTimeslots = { ..._timeslots };
        const mFrom = moment(from_date);
        const mTo = moment(to_date);
        const slot = mFrom.format('HH:mm');

        if (!newTimeslots.hasOwnProperty(slot)) {
          newTimeslots[slot] = [];
        }

        newTimeslots[slot].push({
          mFrom, mTo, from_date, to_date, ...timeslot,
        });

        return newTimeslots;
      }, {});
  }, [hmot, selectedDay, selectedGender]);

  const sortedTimeslots = useMemo(() => Object.entries(timeslots).sort(), [timeslots]);
  const [selectedTimeslot, selectableTimeslots] = useGroupedTimeslots(sortedTimeslots);

  // Wrapper around the book event to push data to Tag Manager.
  const bookWrapper = async (reservation) => {
    const bookingResult = await bookHMOTSession(
      reservation.sfid,
    );
    global.TagManager.pushEvent({
      bookingSuccess: true,
      productStart: reservation.from_date,
      resourceId: reservation.room.facility.sfid,
      locationName: reservation.room.facility.name,
      reservationId: reservation.sfid,
      reservationName: reservation.title,
      type: reservation.product.name,
    });
    return bookingResult;
  };

  const reservations = useMemo(() => (
    selectedTimeslot === null
      ? []
      : selectedTimeslot[1]
        .filter((reservation) => (
          !selectedGender.value
          || selectedGender.value === reservation.staff.gender
        ))
        .map((reservation) => ({
          ...reservation,
          book: async () => bookWrapper(
            reservation,
          ),
        }))
  ), [selectedTimeslot, selectedGender]);


  const [selectedReservation, selectableReservations] = useSelect(reservations, {
    defaultSelected: -1,
    nullable: true,
    onSelect: (selected) => {
      // Once someone expands the are we want to sync that to tag manager
      if (selected !== undefined && selected.selected) {
        global.TagManager.pushEvent({
          bookingHMOTButtonClicked: true,
          productStart: selected.item.from_date,
          resourceId: selected.item.room.facility.sfid,
          locationName: selected.item.room.facility.name,
          reservationId: selected.item.sfid,
          reservationName: selected.item.title,
          type: selected.item.product.event_type,
        });
      }
    },
  });

  useEffect(() => {
    setTerms(false);
  }, [selectedReservation]);

  return {
    timeslots: selectableTimeslots,
    reservations: selectableReservations,
    selectedReservation,
    selectedTimeslot,
    genders,
    selectedGender,
    days: {
      selectedDay,
      days,
      actions,
    },
    terms,
    toggleTerms,
  };
};
