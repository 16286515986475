// Inspired by https://stackoverflow.com/a/13627586

export default (number) => {
  if (number === null) {
    return '';
  }

  const singles = number % 10;
  const tens = number % 100;
  if (singles === 1 && tens !== 11) {
    return `${number}st`;
  }
  if (singles === 2 && tens !== 12) {
    return `${number}nd`;
  }
  if (singles === 3 && tens !== 13) {
    return `${number}rd`;
  }

  return `${number}th`;
};
