import React, { useEffect, useMemo, useState } from 'react';
import useBookingEngine from '../hooks/useBookingEngine';

import './AddressLookup.sass';

const REGEX_POSTCODE = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/;

export default function AddressLookup({ setValue, id }) {
  const [postcode, setPostcode] = useState('');
  const [loadingAddresses, setLoadingAddresses] = useState(false);
  const [postcodeId, setPostcodeId] = useState('');
  const [addressId, setAddressId] = useState('');
  const [loqateAddresses, setLoqateAddresses] = useState([]);
  const [userAddress, setUserAddress] = useState({});
  const [showManualFields, setShowManualFields] = useState(false);
  const [invalidPostcode, setInvalidPostcode] = useState('');
  const bookingEngine = useBookingEngine();

  const validPostcode = useMemo(
    () => (postcode && postcode.match(REGEX_POSTCODE) ? postcode : null),
    [postcode],
  );

  const clearForm = (clearPostCode) => {
    setUserAddress([]);
    setLoqateAddresses([]);
    setShowManualFields(false);
    if (clearPostCode) {
      setPostcode('');
    }
  };

  useEffect(() => {
    setPostcodeId(null);
    setInvalidPostcode(false);
    if (validPostcode) {
      setLoadingAddresses(true);
      bookingEngine.validatePostalCode({ id, postalCode: validPostcode }).then(({ items }) => {
        if (items[0].type !== 'Postcode') {
          setInvalidPostcode(true);
          setLoqateAddresses([]);
        } else {
          setPostcodeId(items[0].id);
        }
      }).catch(() => {
        setPostcodeId(null);
      }).finally(() => setLoadingAddresses(false));
    }
  }, [validPostcode]);

  useEffect(() => {
    clearForm();
    if (postcodeId) {
      setLoadingAddresses(true);
      bookingEngine.getAddressList({ postcodeId }).then(({ items }) => {
        setLoqateAddresses(items);
      }).catch(() => {
        setLoqateAddresses([]);
      }).finally(() => setLoadingAddresses(false));
    }
  }, [postcodeId]);

  useEffect(() => {
    if (addressId) {
      setLoadingAddresses(true);
      bookingEngine.getSpecificAddressDetails({ addressId }).then(({ items }) => {
        setUserAddress(items[0]);
        setShowManualFields(true);
      }).catch(() => {
        setUserAddress([]);
      }).finally(() => setLoadingAddresses(false));
    }
  }, [addressId]);

  const onManualAddressClick = () => {
    setShowManualFields(true);
  };

  useEffect(() => {
    setValue(userAddress);
  }, [userAddress]);

  const updateField = async (value, field) => {
    switch (field) {
      case 'line_one':
        setUserAddress(
          { ...userAddress, line_one: value },
        );
        break;
      case 'line_two':
        setUserAddress(
          { ...userAddress, line_two: value },
        );
        break;
      case 'town_city':
        setUserAddress(
          { ...userAddress, city: value },
        );
        break;
      case 'postcode':
        setUserAddress(
          { ...userAddress, postal_code: value },
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <p>
        Use the postcode field to search for your address.
      </p>
      <div className="mt-20">
        <div className={`field ${(postcode && !validPostcode) ? 'field--invalid' : ''}`}>
          <label
            className="form-field__label"
            htmlFor={`gplookup-postcode-${id}`}
          >
            Postcode
          </label>
          <input
            type="text"
            id={`gplookup-postcode-${id}`}
            value={postcode}
            placeholder="Enter postcode to search"
            className={`text__input ${validPostcode ? 'invalid' : ''}`}
            onInput={(e) => setPostcode(e.target.value)}
            onChange={(e) => setPostcode(e.target.value.toUpperCase())}
          />
          {
            invalidPostcode ? <p className="field-error">We don’t recognise that postcode, please try again</p> : ''
          }
          {
            !showManualFields ? <p className="clickableLinks" onClick={onManualAddressClick}>Enter Address Manually</p> : ''
          }
        </div>
        {loadingAddresses && (
          <div className="mt-20">
            <p>
              <div className="addressLookup__spinner" />
              Searching for addresses...
            </p>
          </div>
        )}
        {loqateAddresses.length > 0 && (
          <div className="mt-20">
            <label
              className="form-field__label"
              htmlFor={`gplookup-practice-${id}`}
            >
              Select your address
            </label>
            <select
              className="select__input"
              onChange={(e) => setAddressId(e.target.value)}
            >
              <option value="???" selected="selected" disabled>Select your address</option>
              {loqateAddresses.map((list) => (
                <option value={list.id}>
                  {`${list.text}, ${list.description}`}
                </option>
              ))}
            </select>
          </div>
        )}

        {showManualFields && (
          <div className="mt-20">
            <div>
              <label className="form-field__label" htmlFor="address_line_1">Address Line 1<sup>*</sup></label>
              <input
                id={`${id}-address_line_1`}
                name="address_line_1"
                type="text"
                value={userAddress.line_one}
                className="text__input"
                onChange={({ target }) => updateField(target.value, 'line_one')}
              />
              <div className="mt-20">
                <label className="form-field__label" htmlFor="address_line_2">Address Line 2</label>
                <input
                  id={`${id}-address_line_2`}
                  name="address_line_2"
                  type="text"
                  value={userAddress.line_two}
                  className="text__input"
                  onChange={({ target }) => updateField(target.value, 'line_two')}
                />
              </div>
              <div className="mt-20">
                <label className="form-field__label" htmlFor="town_city">Town / City<sup>*</sup></label>
                <input
                  id="town_city"
                  name="town_city"
                  type="text"
                  required=""
                  autoComplete="on"
                  value={userAddress.city}
                  className="text__input"
                  onChange={({ target }) => updateField(target.value, 'town_city')}
                />
              </div>
              <div className="mt-20">
                <label className="form-field__label" htmlFor="postcode">Postcode<sup>*</sup></label>
                <input
                  id="postcode"
                  name="postcode"
                  type="text"
                  required=""
                  autoComplete="on"
                  value={userAddress.postal_code}
                  className="text__input"
                  onChange={({ target }) => updateField(target.value, 'postcode')}
                />
              </div>
              {loqateAddresses.length > 0 && (
              <div className="addressDisclaimerContainer">
                <p>
                  Please make sure the details above are correct. If not, please make any necessary changes.
                </p>
              </div>
              )}
              <p className="clickableLinks" onClick={ ()=> clearForm(true)}>Clear form</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
