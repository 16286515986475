import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

export default (actions) => {
  const dispatch = useDispatch();

  return useMemo(
    () => Object
      .entries(actions)
      .reduce((mappedActions, [name, creator]) => ({
        ...mappedActions,
        [name]: (...args) => dispatch(creator(...args)),
      }), {}),
    [actions],
  );
};
